import { TagType } from "@/src/interfaces";
import { IonIcon } from '@ionic/react';
import TagItem from '../../AdminSettings/AdminSettingsTagManagement/TagItem/TagItem';
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
    Root,
} from "../../../../@/components/ui/select";

interface TagItemProps {
    tags: TagType[];
    selectedTags: TagType[];
    handleTagSelectionChange: (option: string) => void;
    handleAddToSelectedTags: () => void;
    unselectTag: (tagId: string) => void;
}

const NewsTagEditSection:React.FC<TagItemProps> = ({tags, selectedTags, handleAddToSelectedTags, handleTagSelectionChange, unselectTag}) => {

    return (
        <div className='mt-6'>
            <div className='mb-2 text-lg'>
                Tags
            </div>
            <div className='flex gap-2 items-center mb-2'>
                <Select onValueChange={(option: string) => handleTagSelectionChange(option)}>
                    <SelectTrigger className="w-[180px] outline-none h-8" >
                        <SelectValue placeholder="Select Tag" />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectGroup>
                            {tags
                                .filter((tag) => !selectedTags.some((selectedTag) => selectedTag.id === tag.id))
                                .map((tag: any) => (
                                    <SelectItem key={`tag-${tag.id}-name${tag.name}`} value={tag.id} >
                                        {tag.name}
                                    </SelectItem>
                                ))}
                        </SelectGroup>
                    </SelectContent>
                </Select>
                <div>
                    <button onClick={tags.length !== selectedTags.length ? handleAddToSelectedTags : () => { }} className="flex items-center px-4 py-1.5 bg-main text-white cursor-pointer text-sm font-semibold rounded transition-colors shadow-md focus:outline-none">
                        <IonIcon icon="add-outline" className="mr-1 text-lg" />
                        Add Tag
                    </button>
                </div>
            </div>
            <div className=' p-4 border rounded-md flex flex-wrap gap-1 bg-white'>
                {selectedTags.map((tag: any) => (
                    <TagItem key={tag.id} tag={tag.name} tagId={tag.id} onDelete={() => unselectTag(tag.id)} />
                ))}
            </div>
        </div>
    )

}

export default NewsTagEditSection;