import { useEffect, useState } from "react";
import AdminBox from "../../Helpers/AdminBox/AdminBox";
import AdimBoxTitle from "../../Helpers/AdminBoxTitle/AdimBoxTitle";
import { ACTIVE_URL } from "../../../../constants";
import { useAuthContext } from '../../../../utils/useAuthContext';
import CKEditorComponent from "../../../../utils/QuillEditor/QuillEditor";
import { IonIcon } from "@ionic/react";

interface SiteTextEditorProps {
    sectionTitle: string;
    get_text_url: string;
    post_text_url: string;
}

const SiteTextEditor: React.FC<SiteTextEditorProps> = ({ sectionTitle, get_text_url, post_text_url }) => {
    const [grText, setGrText] = useState<string>('');
    const [enText, setEnText] = useState<string>('');
    const { authTokens } = useAuthContext();
    const [fetched, setFetched] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [showSuccessIcon, setShowSuccessIcon] = useState<boolean>(false);

    useEffect(() => {
        const fetchResponses = async () => {
            if (!authTokens || !authTokens.access) {
                return;
            }
            try {
                const response = await fetch(`${ACTIVE_URL + get_text_url}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authTokens.access}`,
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    setGrText(data.gr_text);
                    setEnText(data.en_text);
                    setFetched(true);
                } else {
                    console.error('Failed to fetch responses');
                }
            } catch (error) {
                console.error('Error fetching responses:', error);
            }
        };
        fetchResponses();
    }, []);

    const postText = async () => {
        if (!authTokens || !authTokens.access) {
            return;
        }
        try {
            setIsSaving(true);
            const response = await fetch(`${ACTIVE_URL + post_text_url}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authTokens.access}`,
                },
                body: JSON.stringify({
                    grText: grText,
                    enText: enText
                })
            });
            if (response.ok) {
                console.log('Text saved');
                setShowSuccessIcon(true);
                setTimeout(() => {
                    setShowSuccessIcon(false);
                }, 2000);
            } else {
                console.error('Failed to save text');
            }
        } catch (error) {
            console.error('Error saving text:', error);
        } finally {
            setIsSaving(false);
        }
    }

    return (
        <AdminBox>
            <div>
                <div className="flex w-full justify-between items-center ">
                    <AdimBoxTitle title={sectionTitle} />
                </div>
                <div className="px-4 flex flex-col items-start w-full gap-2">
                    {
                        fetched === true &&
                        <div className="w-full flex flex-col">
                            <div className="text-xl mb-2">Greek Text</div>
                            <CKEditorComponent className=' outline-none border-b border-b-neutral-300 w-full mb-4' initialValue={grText} onChange={(e) => setGrText(e)} />
                            <div className="text-xl mb-2">English Text</div>
                            <CKEditorComponent className=' outline-none border-b border-b-neutral-300 w-full' initialValue={enText} onChange={(e) => setEnText(e)} />
                        </div>
                    }
                    <button
                        className={`flex px-4 py-2 text-white rounded-md transition-colors duration-300 ${isSaving ? 'bg-neutral-400 cursor-not-allowed' : 'bg-main hover:bg-blue-600'}`}
                        onClick={postText}
                        disabled={isSaving}
                    >
                        {
                            isSaving ? (
                                <span className="flex items-center">
                                    Saving...
                                    <IonIcon name="sync" className="ml-2 animate-spin" />
                                </span>
                            ) : (
                                <span className="flex items-center">
                                    {showSuccessIcon ? (
                                        <>
                                            <IonIcon name="checkmark-circle" className="mr-2" />
                                            Saved
                                        </>
                                    ) : (
                                        <>
                                            Save Changes
                                        </>
                                    )}
                                </span>
                            )
                        }
                    </button>
                </div>
            </div>
        </AdminBox>
    )
}

export default SiteTextEditor;