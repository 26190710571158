import Services from "../../Components/Services/Services";
import { defaultLanguage } from "../../interfaces";
import React from "react";

const ServicesPage: React.FC<defaultLanguage> = ({activeLanguage}) => {

    return (
        <div className="mt-[120px]">
            <Services activeLanguage={activeLanguage} />
        </div>
    )

}

export default ServicesPage;