
import React, { useState } from 'react';
import { serviceItemType } from '../../../../interfaces';
import { IonIcon } from '@ionic/react';
import { Button } from "../../../../@/components/ui/button";
import { useAuthContext } from '../../../../utils/useAuthContext';
import { ACTIVE_URL } from '../../../../constants'
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
    Root,
} from "../../../../@/components/ui/select";

interface AddServiceProps {
    onComplete: () => void;
}

const AddService:React.FC<AddServiceProps> = ({onComplete}) => {
    const { authTokens } = useAuthContext();
    const [partName, setPartName] = useState<string>('');
    const [desc, setDesc] = useState<string>('');
    const [wp, setWp] = useState<string>('Test Before Invest');


    const addService = async () => {
        if (!authTokens || !authTokens.access) {
            return;
        }
        try {
            const formData = new FormData();
            formData.append('partner', partName);
            formData.append('description', desc);
            formData.append('wp', wp);

            const response = await fetch(`${ACTIVE_URL}/post_service/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${authTokens.access}`
                },
                body: formData
            });

            if (response.ok) {
                console.log('Service edited successfully');
                onComplete();
            } else {
                console.log('Failed to edit service');
            }
        } catch (error) {
            console.log('Failed to edit service');
        }
    }

    return (
        <div className="rounded-md border-main   mb-2 py-2">

        <div className='px-4 flex flex-col gap-4 mb-2'>
            <div className='flex flex-col gap-2'>
                <label className='text-lg text-neutral-800' htmlFor="parent_name">Partner Name</label>
                <input className=' outline-none border-b border-b-neutral-300' type="text" id="parent_name" placeholder="e.g. NCSR Demokritos" value={partName} onChange={(e) => setPartName(e.target.value)} />
            </div>
            <div className='flex flex-col gap-2'>
                <label className='text-lg text-neutral-800' htmlFor="short_desc">Description</label>
                <input className=' outline-none border-b border-b-neutral-300' type="text" id="short_desc" placeholder="Description" value={desc} onChange={(e) => setDesc(e.target.value)} />
            </div>
            <div className='flex flex-col gap-2 select-none'>
                <label className='text-lg text-neutral-800' htmlFor="wp_select">Wp Category</label>
                <Select onValueChange={(option) => setWp(option)} value={wp}>
                    <SelectTrigger className="w-[350px] outline-none h-8">
                        <SelectValue placeholder="Select Service WP" />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectGroup>
                            <SelectItem key={`Test Before Invest`} value={'Test Before Invest'} >
                                Test Before Invest
                            </SelectItem>
                            <SelectItem key={`Innovation ecosystem and networking`} value={'Innovation ecosystem and networking'} >
                                Innovation ecosystem and networking
                            </SelectItem>
                            <SelectItem key={`Skills and training`} value={'Skills and training'} >
                                Skills and training
                            </SelectItem>
                            <SelectItem key={`Support to find investment`} value={'Support to find investment'} >
                                Support to find investment
                            </SelectItem>
                        </SelectGroup>
                    </SelectContent>
                </Select>
            </div>
            <div>
                <Button variant="outline" className="px-3 h-9 text-white bg-main hover:bg-main hover:text-white" onClick={addService}>
                    <IonIcon icon="pencil" className="mr-1 text-md" />
                    Add Service
                </Button>
            </div>
        </div>
    </div>
    )
}

export default AddService;