import React from "react";
import HomeHeader from "../../Components/Home/HomeHeader/HomeHeader";
import HomeNews from "../../Components/Home/HomeNews/HomeNews";
import HomeServices from "../../Components/Home/HomeServices/HomeServices";
import {defaultLanguage} from "../../interfaces";
import HomeIntro from "../../Components/Home/HomeIntro/HomeIntro";

const HomePage:React.FC<defaultLanguage> = ({activeLanguage}) => {


    return (
        <div>
            <HomeHeader activeLanguage={activeLanguage} />
            <HomeIntro activeLanguage={activeLanguage} />
            <HomeServices activeLanguage={activeLanguage} />
            <HomeNews activeLanguage={activeLanguage} />
        </div>
    )
}


export default HomePage;
