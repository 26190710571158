import React, { useState, useEffect } from "react";
import { defaultLanguage } from "../../interfaces";
import ene from '../../assets/images/application_areas/environment.jpg';
import scm from '../../assets/images/application_areas/supply.jpg';
import ct from '../../assets/images/application_areas/culture.jpg';
import { useLocation } from 'react-router-dom';
import fetchSiteText from "../../utils/GetSiteText/GetSiteText";
import HTMLRenderer from "../../utils/HTMLRenderer";

type ApplicationAreasCategory = 'Energy & Environment' | 'Supply Chain & Mobility' | 'Culture & Tourism';

const ApplicationAreasPage: React.FC<defaultLanguage> = ({ activeLanguage }) => {
    const [activeCategory, setActiveCategory] = useState<ApplicationAreasCategory>('Energy & Environment');
    const location = useLocation();

    const [aaTitleGr, setaaTitleGr] = useState<string>('');
    const [aaTitleEn, setaaTitleEn] = useState<string>('');
    const [aaEeTitleGr, setaaEeTitleGr] = useState<string>('');
    const [aaEeTitleEn, setaaEeTitleEn] = useState<string>('');
    const [aaScmTitleGr, setaaScmTitleGr] = useState<string>('');
    const [aaScmTitleEn, setaaScmTitleEn] = useState<string>('');
    const [aaCtTitleGr, setaaCtTitleGr] = useState<string>('');
    const [aaCtTitleEn, setaaCtTitleEn] = useState<string>('');
    const [aaEeTextGr, setaaEeTextGr] = useState<string>('');
    const [aaEeTextEn, setaaEeTextEn] = useState<string>('');
    const [aaScmTextGr, setaaScmTextGr] = useState<string>('');
    const [aaScmTextEn, setaaScmTextEn] = useState<string>('');
    const [aaCtTextGr, setaaCtTextGr] = useState<string>('');
    const [aaCtTextEn, setaaCtTextEn] = useState<string>('');

    useEffect(() => {
        const fetchResponses = async () => {
            const aaTitle = await fetchSiteText('home_aa_title');
            const aaEeTitle = await fetchSiteText('home_aa_ee_title');
            const aaScmTitle = await fetchSiteText('home_aa_scm_title');
            const aaCtTitle = await fetchSiteText('home_aa_ct_title');
            const aaEeText = await fetchSiteText('aa_page_ee_text');
            const aaScmText = await fetchSiteText('aa_page_scm_text');
            const aaCtText = await fetchSiteText('aa_page_ct_text');

            if (aaTitle && aaEeTitle && aaScmTitle && aaCtTitle && aaEeText && aaScmText && aaCtText) {
                setaaTitleGr(aaTitle.gr_text);
                setaaTitleEn(aaTitle.en_text);
                setaaEeTitleGr(aaEeTitle.gr_text);
                setaaEeTitleEn(aaEeTitle.en_text);
                setaaScmTitleGr(aaScmTitle.gr_text);
                setaaScmTitleEn(aaScmTitle.en_text);
                setaaCtTitleGr(aaCtTitle.gr_text);
                setaaCtTitleEn(aaCtTitle.en_text);
                setaaEeTextGr(aaEeText.gr_text);
                setaaEeTextEn(aaEeText.en_text);
                setaaScmTextGr(aaScmText.gr_text);
                setaaScmTextEn(aaScmText.en_text);
                setaaCtTextGr(aaCtText.gr_text);
                setaaCtTextEn(aaCtText.en_text);
            }

        };
        fetchResponses();
    }, []);

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const categoryParam = urlParams.get('category');
        if (categoryParam) {
            const category = decodeURIComponent(categoryParam) as ApplicationAreasCategory;
            setActiveCategory(category);
        }
    }, [location.search]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="w-full flex items-center justify-center mb-10 mt-[100px] px-2 md:px-0">
            <div className="max-w-1128 m-auto w-full bg-main h-full rounded-md text-neutral-200 flex flex-col items-center p-8">
                <h2 className="text-2xl md:text-3xl mt-3 md:mt-5 text-center raisonne-pro-font">
                    <HTMLRenderer htmlContent={activeLanguage === 'en' ? aaTitleEn : aaTitleGr} />
                </h2>
                <div className="w-full border-b border-b-white border-b-[0.5px] mt-6 md:mt-8 grid grid-cols-1 md:grid-cols-3 select-none gap-2 md:gap-0">
                    {['Energy & Environment', 'Supply Chain & Mobility', 'Culture & Tourism'].map((category) => (
                        <div
                            key={category}
                            className={`flex items-center justify-center py-2 px-4 cursor-pointer rounded-md md:rounded-t-md raisonne-pro-font text-center
                        ${activeCategory == category ? 'bg-neutral-100 text-main' : 'hover:bg-neutral-200 hover:text-main'} duration-200`}
                            onClick={() => setActiveCategory(category as ApplicationAreasCategory)}
                        >
                            <HTMLRenderer htmlContent={activeLanguage === 'en' ?
                                (category === 'Energy & Environment' ? aaEeTitleEn : category === 'Supply Chain & Mobility' ? aaScmTitleEn : aaCtTitleEn) :
                                (category === 'Energy & Environment' ? aaEeTitleGr : category === 'Supply Chain & Mobility' ? aaScmTitleGr : aaCtTitleGr)
                            } />
                        </div>
                    ))}
                </div>
                <div className="flex flex-col md:grid md:grid-cols-3 h-full w-full pt-4">
                    <div className="h-48 md:h-full w-full rounded-md h-full" style={{
                        backgroundImage: `url(${activeCategory == 'Energy & Environment' ? ene : activeCategory == 'Supply Chain & Mobility' ? scm : ct})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                    }}></div>
                    <div className="md:col-span-2 p-2 md:p-5 h-full flex md:items-center">
                        {activeCategory == 'Energy & Environment' &&
                            <div className="flex flex-col gap-4">
                                <h3 className="mt-2 md:mt-5 text-xl md:text-2xl">
                                    <HTMLRenderer htmlContent={activeLanguage === 'en' ? aaEeTitleEn : aaEeTitleGr} />
                                </h3>
                                <HTMLRenderer htmlContent={activeLanguage === 'en' ? aaEeTextEn : aaEeTextGr} className="text-sm md:text-base" />
                            </div>
                        }
                        {activeCategory == 'Supply Chain & Mobility' &&
                            <div className="flex flex-col gap-4">
                                <h3 className="mt-2 md:mt-5 text-xl md:text-2xl">
                                    <HTMLRenderer htmlContent={activeLanguage === 'en' ? aaScmTitleEn : aaScmTitleGr} />
                                </h3>
                                <HTMLRenderer htmlContent={activeLanguage === 'en' ? aaScmTextEn : aaScmTextGr} className="text-sm md:text-base" />
                            </div>
                        }
                        {activeCategory == 'Culture & Tourism' &&
                            <div className="flex flex-col gap-4">
                                <h3 className="mt-2 md:mt-5 text-xl md:text-2xl">
                                    <HTMLRenderer htmlContent={activeLanguage === 'en' ? aaCtTitleEn : aaCtTitleGr} />
                                </h3>
                                <HTMLRenderer htmlContent={activeLanguage === 'en' ? aaCtTextEn : aaCtTextGr} className="text-sm md:text-base" />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>

        // <div className="w-full flex items-center justify-center h-[calc(100vh_-_140px)] max-h-[800px] mb-10 mt-[100px]">
        //     <div className="max-w-1128 m-auto w-full bg-main h-full rounded-md text-neutral-200 flex flex-col items-center p-8">
        //         <h2 className="text-3xl mt-5 raisonne-pro-font">
        //             <HTMLRenderer htmlContent={activeLanguage === 'en' ? aaTitleEn : aaTitleGr} />
        //         </h2>
        //         <div className="w-full border-b border-b-white border-b-[0.5px] mt-8 grid grid-cols-3 select-none ">
        //             <div className={`flex items-center justify-center py-2  cursor-pointer rounded-t-md raisonne-pro-font ${activeCategory == 'Energy & Environment' ? 'bg-neutral-100 text-main' : 'hover:bg-neutral-200 hover:text-main'} duration-200`} onClick={() => setActiveCategory('Energy & Environment')}>
        //                 <HTMLRenderer htmlContent={activeLanguage === 'en' ? aaEeTitleEn : aaEeTitleGr} />
        //             </div>
        //             <div className={`flex items-center justify-center py-2  cursor-pointer rounded-t-md raisonne-pro-font ${activeCategory == 'Supply Chain & Mobility' ? 'bg-neutral-100 text-main' : 'hover:bg-neutral-200 hover:text-main'} duration-200`} onClick={() => setActiveCategory('Supply Chain & Mobility')}>
        //                 <HTMLRenderer htmlContent={activeLanguage === 'en' ? aaScmTitleEn : aaScmTitleGr} />
        //             </div>
        //             <div className={`flex items-center justify-center py-2  cursor-pointer rounded-t-md raisonne-pro-font ${activeCategory == 'Culture & Tourism' ? 'bg-neutral-100 text-main' : 'hover:bg-neutral-200 hover:text-main'} duration-200`} onClick={() => setActiveCategory('Culture & Tourism')}>
        //                 <HTMLRenderer htmlContent={activeLanguage === 'en' ? aaCtTitleEn : aaCtTitleGr} />
        //             </div>
        //         </div>
        //         <div className="grid grid-cols-3 h-full w-full pt-4">
        //             <div className="h-full w-full rounded-md" style={{
        //                 backgroundImage: `url(${activeCategory == 'Energy & Environment' ? ene : activeCategory == 'Supply Chain & Mobility' ? scm : ct})`,
        //                 backgroundSize: 'cover',
        //                 backgroundPosition: 'center'
        //             }}></div>
        //             <div className="col-span-2 p-5 h-full flex items-center">
        //                 {activeCategory == 'Energy & Environment' &&
        //                     <div className="flex flex-col gap-4">
        //                         <h3 className="mt-5 text-2xl">
        //                             <HTMLRenderer htmlContent={activeLanguage === 'en' ? aaEeTitleEn : aaEeTitleGr} />
        //                         </h3>
        //                         <HTMLRenderer htmlContent={activeLanguage === 'en' ? aaEeTextEn : aaEeTextGr} className="text-base" />
        //                     </div>
        //                 }
        //                 {activeCategory == 'Supply Chain & Mobility' &&
        //                     <div className="flex flex-col gap-4">
        //                         <h3 className="mt-5 text-2xl">
        //                             <HTMLRenderer htmlContent={activeLanguage === 'en' ? aaScmTitleEn : aaScmTitleGr} />
        //                         </h3>
        //                         <HTMLRenderer htmlContent={activeLanguage === 'en' ? aaScmTextEn : aaScmTextGr} className="text-base" />
        //                     </div>
        //                 }
        //                 {activeCategory == 'Culture & Tourism' &&
        //                     <div className="flex flex-col gap-4">
        //                         <h3 className="mt-5 text-2xl">
        //                             <HTMLRenderer htmlContent={activeLanguage === 'en' ? aaCtTitleEn : aaCtTitleGr} />
        //                         </h3>
        //                         <HTMLRenderer htmlContent={activeLanguage === 'en' ? aaCtTextEn : aaCtTextGr} className="text-base" />
        //                     </div>
        //                 }
        //             </div>
        //         </div>
        //     </div>
        // </div>

        
    )
}

export default ApplicationAreasPage;