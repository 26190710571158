import React, { useState } from 'react';
import { useAuthContext } from '../../../utils/useAuthContext';
import AdminMainHeader from '../AdminMainHeader/AdminMainHeader';

import AdminSettingsTagManagement from './AdminSettingsTagManagement/AdminSettingsTagManagement';
// import AdminSettingsStatusManagement from './AdminSettingsStatusManagement/AdminSettingsStatusManagement';

const AdminSettings = () => {
    const { authTokens } = useAuthContext();

    return (
        <div className=''>
            <AdminMainHeader pageTitle='Settings' />

            <AdminSettingsTagManagement />
        </div>
    )
};

export default AdminSettings;