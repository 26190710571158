import React, { useState } from 'react';
import './AdminWrapper.css';
import AdminSideBar from '../AdminSideBar/AdminSideBar';
import AdminHome from '../AdminHome/AdminHome';
import AdminSettings from '../AdminSettings/AdminSettings';
import AdminNews from '../AdminNews/AdminNews';
import AdminPartners from '../AdminPartners/AdminPartners';
import AdminServices from '../AdminServices/AdminServices';
import AdminSiteTexts from '../AdminSiteTexts/AdminSiteTexts';

const AdminWrapper: React.FC = () => {
    const [activeScreen, setActiveScreen] = useState<string>('CandidatesMain');

    const setActiveScreenHandler = (screen: string) => {
        setActiveScreen(screen);
    };

    return (
        <div className='admin-wrapper'>
            <AdminSideBar activeScreen={activeScreen}  setActiveScreen={setActiveScreenHandler}  />
            <div className='admin-wrapper-content-wrapper'>
                {activeScreen === 'dashboard' && <AdminHome />}
                {activeScreen === 'settings' && <AdminSettings />}
                {activeScreen === 'News' && <AdminNews />}
                {activeScreen === 'siteTexts' && <AdminSiteTexts />}
                {activeScreen === 'Partners' && <AdminPartners />}
                {activeScreen === 'Services' && <AdminServices />}

            </div>
        </div>
    )
};

export default AdminWrapper;