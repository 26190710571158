import React, { useContext, useState } from 'react';
import './AdminSideBar.css';
import logo_blue from '../../../assets/images/logos/SmartAttica_Logo_Colored_300dpi.png';
import { IonIcon } from '@ionic/react';
import AuthContext from '../../../utils/AuthContext';


interface AdminSideBarProps {
    activeScreen: string;
    setActiveScreen: (screen: string) => void;
}

const AdminSideBar: React.FC<AdminSideBarProps> = ({ activeScreen, setActiveScreen }) => {
    const [showDriversFormLinks, setDriversFormLinks] = useState<boolean>(false);

    let { logoutUser } = useContext(AuthContext) as any;

    const hanldeLinkGroupStateChange = (setter: (state: boolean) => void, newValue: boolean) => {
        setter(newValue);
        console.log(showDriversFormLinks)
    }

    const handleMenuItemClick = (screen: string) => {
        setActiveScreen(screen);
    };

    return (
        <div className='admin-sidebar overflow-y-auto text-main'>
            <a href='/' className="flex items-center justify-center admin-logo mt-2 mb-2">
                <img src={logo_blue} alt="" className='h-16' />
            </a>
            <div className='sidebar-menu'>
                <div className={`sidebar-menu-item ${activeScreen === 'dashboard' ? 'active' : ''}`} onClick={() => handleMenuItemClick('dashboard')}>
                    <IonIcon icon="home-sharp" className="text-main font-semibold ion-icon-item" /> Dashboard
                </div>
                <div className={`sidebar-menu-item ${activeScreen === 'Services' ? 'active' : ''}`} onClick={() => handleMenuItemClick('Services')}>
                    <IonIcon icon="briefcase" className="text-main font-semibold ion-icon-item" /> Services
                </div>
                <div className={`sidebar-menu-item ${activeScreen === 'News' ? 'active' : ''}`} onClick={() => handleMenuItemClick('News')}>
                    <IonIcon icon="newspaper" className="text-main font-semibold ion-icon-item" /> News
                </div>
                <div className={`sidebar-menu-item ${activeScreen === 'Partners' ? 'active' : ''}`} onClick={() => handleMenuItemClick('Partners')}>
                    <IonIcon icon="people" className="text-main font-semibold ion-icon-item" /> Partners
                </div>
                <div className={`sidebar-menu-item ${activeScreen === 'siteTexts' ? 'active' : ''}`} onClick={() => handleMenuItemClick('siteTexts')}>
                    <IonIcon icon="document-text" className="text-main font-semibold ion-icon-item" /> Site Texts
                </div>
            </div>
            <div className={`sidebar-menu-item logout ${activeScreen === 'users' ? 'active' : ''}`} onClick={() => handleMenuItemClick('users')}>
                <IonIcon icon="id-card-outline" className="text-main font-semibold ion-icon-item" /> Users
            </div>
            <div className={`sidebar-menu-item logout ${activeScreen === 'settings' ? 'active' : ''}`} onClick={() => handleMenuItemClick('settings')}>
                <IonIcon icon="settings" className="text-main font-semibold ion-icon-item" /> Settings
            </div>
            {/* <div className={`sidebar-menu-item logout ${activeScreen === 'logs' ? 'active' : ''}`} onClick={() => handleMenuItemClick('logs')}>
                <IonIcon icon="code-slash-outline" className="text-blue-800 font-semibold ion-icon-item" /> Logs
            </div> */}
            <div className='sidebar-menu-item logout' onClick={logoutUser}>
                <IonIcon icon="log-out" className="text-main font-semibold ion-icon-item" /> Logout
            </div>
        </div>
    )
};

export default AdminSideBar;