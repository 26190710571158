import React, { useEffect, useState, useRef } from "react";
import { TagType, defaultLanguage } from "../../../interfaces";
import { ACTIVE_URL } from "../../../constants";
import greekLanguage from '../../../locales/gr.json';
import englishLanguage from '../../../locales/en.json';
import HomeNewsItem from "./HomeNewsItem/HomeNewsItem";
import { IonIcon } from "@ionic/react";
import fetchSiteText from "../../../utils/GetSiteText/GetSiteText";
import HTMLRenderer from "../../../utils/HTMLRenderer";

type NewsItem = {
    grTitle: string;
    enTitle: string;
    grDescription: string;
    enDescription: string;
    image: string;
    slug: string;
    date: string;
    tags: TagType[];
};

const HomeNews: React.FC<defaultLanguage> = ({ activeLanguage }) => {
    const [newsItems, setNewsItems] = useState<NewsItem[]>([{} as NewsItem, {} as NewsItem, {} as NewsItem]);
    const fetched = useRef<boolean>(false);
    const [loadingItems, setLoadingItems] = useState<boolean>(true);
    const [hoverTitle, setHoverTitle] = useState<boolean>(false);
    const languageData = activeLanguage === 'el' ? greekLanguage : englishLanguage;


    const [titleGr, setTitleGr] = useState<string>('');
    const [titleEn, setTitleEn] = useState<string>('');
    const [moreGr, setMoreGr] = useState<string>('');
    const [moreEn, setMoreEn] = useState<string>('');
    useEffect(() => {
        const fetchResponses = async () => {
            const titleData = await fetchSiteText('home_news_title');
            const moreData = await fetchSiteText('home_news_view_all');
            if (titleData && moreData) {
                setTitleGr(titleData.gr_text);
                setTitleEn(titleData.en_text);
                setMoreGr(moreData.gr_text);
                setMoreEn(moreData.en_text);
            }
        };
        fetchResponses();
    }, []);
    useEffect(() => {
        const fetchResponses = async () => {
            try {
                const response = await fetch(`${ACTIVE_URL}/get_recent_news/`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    setNewsItems([])
                    let temp_arr: any = [];
                    data.map((item: any) => {
                        if (item.slug in temp_arr) return;
                        setNewsItems((prev) => [...prev, {
                            grTitle: item.gr_title,
                            enTitle: item.en_title,
                            grDescription: item.gr_description,
                            enDescription: item.en_description,
                            image: item.image_data,
                            date: item.date,
                            slug: item.slug,
                            tags: item.tags,
                        }]);
                        temp_arr.push(item.slug);
                    })
                    setLoadingItems(false);
                } else {
                    console.error('Failed to fetch responses');
                }
            } catch (error) {
                console.error('Error fetching responses:', error);
            }
        }
        // Cleanup function
        if (!fetched.current) {
            fetchResponses();
            fetched.current = true;
        }
    }, []);

    useEffect(() => {
        const tempArr: any = [];
        newsItems.forEach((item, index) => {
            if (index === newsItems.length - 1) {
                if (tempArr.includes(item.slug)) {
                    setNewsItems(prev => prev.slice(0, -1));
                } else {
                    tempArr.push(item.slug);
                }
            }
        });
    }, [newsItems]);

    return (
        <div className="py-8 md:pt-16 md:pb-28 px-4 md:px-3 lg:px-0 max-w-1128 m-auto">
            <div className="flex flex-col sm:flex-row justify-between w-full items-center select-none mb-6 md:mb-0">
                <h1 className='text-center md:text-left mt-4 md:mt-10 mb-4 md:mb-8 text-2xl md:text-3xl lg:text-4xl font-semibold'>
                    <HTMLRenderer htmlContent={activeLanguage == 'en' ? titleEn : titleGr} className="text-neutral-800 font-semibold raisonne-pro-font" />
                </h1>
                <div className="hidden sm:block">
                    <a href="/news" className="duration-100 flex text-neutral-700 hover:text-main transition text-base md:text-lg font-semibold items-center gap-2" onMouseOver={() => setHoverTitle(true)} onMouseLeave={() => setHoverTitle(false)}>
                        <div className="duration-100">
                            <HTMLRenderer htmlContent={activeLanguage == 'en' ? moreEn : moreGr} className="text-sm md:text-base" />
                        </div>
                        <IonIcon icon='arrow-forward-outline' className={`text-lg md:text-xl duration-200 inline-block ${hoverTitle ? 'translate-x-0.5' : ''}`} />
                    </a>
                </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-4 lg:gap-6">
                {newsItems.map((item, index) => (
                    <div key={index}>
                        <HomeNewsItem loading={loadingItems} grTitle={item.grTitle} enTitle={item.enTitle} grDescription={item.grDescription} tags={item.tags} enDescription={item.enDescription} image={item.image} slug={item.slug} date={item.date} activeLanguage={activeLanguage} />
                    </div>
                ))}
            </div>
            <div className="mt-6 sm:hidden">
                <a href="/news" className="duration-100 flex justify-center text-neutral-700 hover:text-main transition text-base font-semibold items-center gap-2" onMouseOver={() => setHoverTitle(true)} onMouseLeave={() => setHoverTitle(false)}>
                    <div className="duration-100">
                        <HTMLRenderer htmlContent={activeLanguage == 'en' ? moreEn : moreGr} className="text-sm" />
                    </div>
                    <IonIcon icon='arrow-forward-outline' className={`text-lg duration-200 inline-block ${hoverTitle ? 'translate-x-0.5' : ''}`} />
                </a>
            </div>
        </div>
    );
}

export default HomeNews;