import { IonIcon } from "@ionic/react";
import { ACTIVE_MEDIA_URL } from "../../../constants";
import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { close, arrowForward, arrowBack } from 'ionicons/icons';

const ImagesCarousel = ({ images }: { images: string[] }) => {
   
    const [carouselWidth, setCarouselWidth] = useState<number>(0);
    const carousel = useRef<HTMLDivElement>(null);
    const innerCarousel = useRef<HTMLDivElement>(null);
    const [isDragging, setIsDragging] = useState(false);
    const [dragStartX, setDragStartX] = useState<number>(0);
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
    const [fullscreenIndex, setFullscreenIndex] = useState<number | null>(null);

    useEffect(() => {
        if (carousel.current && innerCarousel.current) {
            setCarouselWidth(innerCarousel.current.scrollWidth - carousel.current.offsetWidth);
        }
    }, [images]);

    const handleMouseDown = (event: React.MouseEvent) => {
        setIsDragging(false);
        setDragStartX(event.clientX);
    };

    const handleMouseMove = (event: React.MouseEvent) => {
        if (Math.abs(event.clientX - dragStartX) > 5) {
            setIsDragging(true);
        }
    };

    const handleMouseUp = (event: React.MouseEvent, index: number) => {
        if (!isDragging) {
            handleImageClick(index);
        }
        setIsDragging(false);
    };

    const handleImageClick = (index: number) => {
        setFullscreenIndex(index);
    };

    const closeFullscreen = () => {
        setFullscreenIndex(null);
    };

    const nextImage = () => {
        if (fullscreenIndex !== null) {
            setFullscreenIndex((fullscreenIndex + 1) % images.length);
        }
    };

    const prevImage = () => {
        if (fullscreenIndex !== null) {
            setFullscreenIndex((fullscreenIndex - 1 + images.length) % images.length);
        }
    };

    return (
        <>
            <div
                ref={carousel}
                className='carousel overflow-x-auto cursor-grab z-0 w-full my-3'
                style={{
                    msOverflowStyle: 'none',
                    scrollbarWidth: 'none',
                }}
            >
                <motion.div
                    ref={innerCarousel}
                    drag='x'
                    dragConstraints={{ right: 0, left: -carouselWidth }}
                    dragElastic={0}
                    dragTransition={{ bounceStiffness: 80, bounceDamping: 10 }}
                    className='inner-carousel flex z-0 items-center h-full w-max'
                >
                    {images.length > 0 && images.map((image, index) => (
                        <div
                            key={index}
                            className='pr-8 h-full'
                            onMouseDown={handleMouseDown}
                            onMouseMove={handleMouseMove}
                            onMouseUp={(e) => handleMouseUp(e, index)}
                            onMouseEnter={() => setHoveredIndex(index)}
                            onMouseLeave={() => setHoveredIndex(null)}
                        >
                            <div className='overflow-hidden rounded-md pointer-events-none flex h-full items-center'>
                                <img
                                    src={`${ACTIVE_MEDIA_URL}${image}`}
                                    alt={image}
                                    className='w-auto object-contain max-h-[100px] min-w-[100px]'
                                />
                            </div>
                        </div>
                    ))}
                </motion.div>
            </div>

            {fullscreenIndex !== null && (
                <div className="fixed inset-0 bg-black bg-opacity-90 flex items-center justify-center z-50">
                    <div className="relative w-full h-full flex items-center justify-center">
                        <img
                            src={`${ACTIVE_MEDIA_URL}${images[fullscreenIndex]}`}
                            alt={images[fullscreenIndex]}
                            className="max-h-[70vh] max-w-[70vw] sm:max-h-[80vh] sm:max-w-[80vw] md:max-h-[85vh] md:max-w-[85vw] w-auto h-auto object-contain"
                        />
                        <button 
                            onClick={closeFullscreen} 
                            className="absolute top-2 right-2 sm:top-4 sm:right-4 text-white p-2  rounded-full"
                            aria-label="Close fullscreen"
                        >
                            <IonIcon icon={close} className="w-4 h-4 sm:w-6 sm:h-6" />
                        </button>
                    </div>
                    <button 
                        onClick={prevImage} 
                        className="absolute left-1 sm:left-4 top-1/2 transform -translate-y-1/2 text-white p-1 sm:p-2 bg-opacity-50 rounded-full"
                        aria-label="Previous image"
                    >
                        <IonIcon icon={arrowBack} className="w-4 h-4 sm:w-6 sm:h-6" />
                    </button>
                    <button 
                        onClick={nextImage} 
                        className="absolute right-1 sm:right-4 top-1/2 transform -translate-y-1/2 text-white p-1 sm:p-2 bg-opacity-50 rounded-full"
                        aria-label="Next image"
                    >
                        <IonIcon icon={arrowForward} className="w-4 h-4 sm:w-6 sm:h-6" />
                    </button>
                    <div className="absolute bottom-2 sm:bottom-4 left-0 right-0 text-center text-white text-sm sm:text-base">
                        {fullscreenIndex + 1} / {images.length}
                    </div>
                </div>
            )}
        </>
    );
}

export default ImagesCarousel;