import React, { useEffect, useRef, useState } from 'react';
import AdminMainHeader from '../AdminMainHeader/AdminMainHeader';
import AdminBox from '../Helpers/AdminBox/AdminBox';
import AdimBoxTitle from '../Helpers/AdminBoxTitle/AdimBoxTitle';
import { IonIcon } from '@ionic/react';
import { Button } from '../../../@/components/ui/button';
import AddNews from './AddNews/AddNews';
import { useAuthContext } from '../../../utils/useAuthContext';
import { ACTIVE_URL } from '../../../constants';
import AdminNewsItem from './AdminNewsItem/AdminNewsItem';
import { TagType } from '@/src/interfaces';

export type NewsItem = {
    id: number;
    grTitle: string;
    enTitle: string;
    grDescription: string;
    enDescription: string;
    image: string | File;
    slug: string;
    date: string;
    active: boolean;
    featured: boolean;
    tags: TagType[];
    pdfAttachments: { id: string, name: string, url: string }[];
    additionalImages: { id: string, name: string, url: string }[];
};

const AdminNews = () => {
    const { authTokens } = useAuthContext();
    const [view, setView] = useState<string>('overview');
    const [newsItems, setNewsItems] = useState<NewsItem[]>([]);
    const fetched = useRef<boolean>(false);
    const [loadingItems, setLoadingItems] = useState<boolean>(true);
    const [allTags, setAllTags] = useState<TagType[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [itemsPerPage, setItemsPerPage] = useState<number>(5); // Number of news items per page
    const [reloadTrigger, setReloadTrigger] = useState(0);

    const reloadNewsItems = () => {
        setReloadTrigger(prev => prev + 1);
    };

    useEffect(() => {

        const fetchTags = async () => {
            if (!authTokens || !authTokens.access) {
                return;
            }
            try {
                const response = await fetch(`${ACTIVE_URL}/get_all_tags/`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authTokens.access}`,
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    setAllTags(data);
                } else {
                    console.error('Failed to fetch tags');
                }
            } catch (error) {
                console.error('Error fetching tags:', error);
            }
        };
        fetchTags();
    }, [authTokens]);

    const toggleView = () => {
        setView((prevView) => (prevView === 'overview' ? 'add' : 'overview'));
    }

    useEffect(() => {
        const fetchResponses = async () => {
            try {
                const response = await fetch(`${ACTIVE_URL}/get_all_news/`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authTokens?.access}`,
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    const uniqueNewsItems = Array.from(new Set(data.map((item: any) => item.id)))
                        .map(id => {
                            return data.find((item: any) => item.id === id);
                        })
                        .map((item: any) => {
                            const pdfAttachments = item.pdf_attachments.map((pdf: any) => ({
                                name: pdf.media_url.split('/').pop(),
                                url: pdf.media_url,
                                id: pdf.id.toString(), 
                            }));
                            const additionalImages = item.image_attachments.map((image: any) => ({
                                name: image.media_url.split('/').pop(),
                                url: image.media_url,
                                id: image.id.toString(), 
                            }));
                            return {
                                id: item.id,
                                grTitle: item.gr_title,
                                enTitle: item.en_title,
                                grDescription: item.gr_description,
                                enDescription: item.en_description,
                                image: item.image_data,
                                date: item.date,
                                slug: item.slug,
                                active: item.active,
                                featured: item.featured,
                                tags: item.tags,
                                pdfAttachments,
                                additionalImages
                            };
                        });
                    setNewsItems(uniqueNewsItems);
                    setLoadingItems(false);
                } else {
                    console.error('Failed to fetch responses');
                }
            } catch (error) {
                console.error('Error fetching responses:', error);
            }
        }
        fetchResponses();
        // Cleanup function
        // if (!fetched.current) {
        //     fetchResponses();
        //     fetched.current = true;
        // }
    }, [authTokens, reloadTrigger]);
    
    const totalPages = Math.ceil(newsItems.length / itemsPerPage);

    const goToPreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const goToNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };

    const handleReduceItemsPerPage = () => {
        setItemsPerPage((prevItemsPerPage) => Math.max(prevItemsPerPage - 1, 1));
    };

    const handleIncreaseItemsPerPage = () => {
        setItemsPerPage((prevItemsPerPage) => Math.min(prevItemsPerPage + 1, newsItems.length));
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = newsItems.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <div>
            <AdminMainHeader pageTitle='News' />
            <AdminBox>
                {view === 'overview' ? (
                    <div className='px-2'>
                        <div className='flex justify-between items-center mr-4'>
                            <AdimBoxTitle title='News Overview' />
                            <Button variant="outline" className="px-3 h-9 text-white bg-main hover:bg-blue-600 hover:text-white" onClick={toggleView}>
                                <IonIcon icon="add-outline" className="mr-1 text-lg" />
                                Add News
                            </Button>
                        </div>
                        <div className="w-full px-4 py-4">
                            <div className="w-full flex justify-between items-center select-none">
                                <div className="flex items-center gap-2 text-sm">
                                    <button className="py-1 px-2 bg-neutral-200 rounded-md cursor-pointer flex gap-0.5 items-center text-neutral-700 hover:text-neutral-900 hover:shadow-md" onClick={goToPreviousPage} disabled={currentPage === 1}>
                                        <IonIcon icon="chevron-back" />
                                        <div>Previous</div>
                                    </button>
                                    <span>{currentPage} out of {totalPages}</span>
                                    <button className="py-1 px-2 bg-neutral-200 rounded-md cursor-pointer flex gap-0.5 items-center text-neutral-700 hover:text-neutral-900 hover:shadow-md" onClick={goToNextPage} disabled={currentPage === totalPages}>
                                        <div>Next</div>
                                        <IonIcon icon="chevron-forward" />
                                    </button>
                                </div>
                                <div className="flex items-center justify-center gap-2 text-sm">
                                    <div>Items per page:</div>
                                    <div
                                        className="py-1 w-8 flex items-center justify-center bg-neutral-200 rounded-md cursor-pointer flex gap-0.5 items-center text-neutral-700 hover:text-neutral-900 hover:shadow-md"
                                        onClick={handleReduceItemsPerPage}
                                    >
                                        -
                                    </div>
                                    <div>
                                        {itemsPerPage}
                                    </div>
                                    <div
                                        className="py-1 w-8 flex items-center justify-center bg-neutral-200 rounded-md cursor-pointer flex gap-0.5 items-center text-neutral-700 hover:text-neutral-900 hover:shadow-md"
                                        onClick={handleIncreaseItemsPerPage}
                                    >
                                        +
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="px-4 flex flex-col gap-3 ">
                            {currentItems.map((item, index) => (
                                <div key={index}>
                                    <AdminNewsItem loading={loadingItems} item={item} allTags={allTags} onUpdate={reloadNewsItems} />
                                </div>
                            ))}
                        </div>
                        <Button variant="outline" className="ml-4 mt-4 px-3 h-9 text-white bg-main hover:bg-main hover:text-white" onClick={toggleView}>
                            <IonIcon icon="add-outline" className="mr-1 text-lg" />
                            Add News
                        </Button>
                    </div>
                ) : (
                    <>
                        <AdimBoxTitle title='Add News' />
                        <AddNews onComplete={toggleView} />
                    </>
                )}
            </AdminBox>
        </div>
    );
}

export default AdminNews;
