import React, { useEffect, useState } from "react";
import './HomeServices.css';
import { defaultLanguage } from "../../../interfaces";
import HomeServicesCard from "./HomeServicesCard/HomeServicesCard";
import homeIntroBg from "../../../assets/images/home_pattern_bg.png";
import blueBg from "../../../assets/images/application_areas/3.jpg";
import fetchSiteText from "../../../utils/GetSiteText/GetSiteText";
import HTMLRenderer from "../../../utils/HTMLRenderer";

const HomeServices: React.FC<defaultLanguage> = ({ activeLanguage }) => {

    const [titleGr, setTitleGr] = useState<string>('');
    const [titleEn, setTitleEn] = useState<string>('');

    const [tbiTitleGr, setTbiTitleGr] = useState<string>('');
    const [tbiTitleEn, setTbiTitleEn] = useState<string>('');
    const [tbiTextGr, setTbiTextGr] = useState<string>('');
    const [tbiTextEn, setTbiTextEn] = useState<string>('');
    const [ienTitleGr, setIenTitleGr] = useState<string>('');
    const [ienTitleEn, setIenTitleEn] = useState<string>('');
    const [ienTextGr, setIenTextGr] = useState<string>('');
    const [ienTextEn, setIenTextEn] = useState<string>('');
    const [stTitleGr, setStTitleGr] = useState<string>('');
    const [stTitleEn, setStTitleEn] = useState<string>('');
    const [stTextGr, setStTextGr] = useState<string>('');
    const [stTextEn, setStTextEn] = useState<string>('');
    const [sfiTitleGr, setSfiTitleGr] = useState<string>('');
    const [sfiTitleEn, setSfiTitleEn] = useState<string>('');
    const [sfiTextGr, setSfiTextGr] = useState<string>('');
    const [sfiTextEn, setSfiTextEn] = useState<string>('');


    useEffect(() => {
        const fetchResponses = async () => {
            const servicesTitleData = await fetchSiteText('home_ser_title');
            const tbiTitleData = await fetchSiteText('home_ser_tbi_title');
            const tbiTextData = await fetchSiteText('home_ser_tbi_text');
            const ienTitleData = await fetchSiteText('home_ser_ien_title');
            const ienTextData = await fetchSiteText('home_ser_ien_text');
            const stTitleData = await fetchSiteText('home_ser_st_title');
            const stTextData = await fetchSiteText('home_ser_st_text');
            const sfiTitleData = await fetchSiteText('home_ser_sfi_title');
            const sfiTextData = await fetchSiteText('home_ser_sfi_text');

            if (servicesTitleData && tbiTitleData && tbiTextData && ienTitleData && ienTextData && stTitleData && stTextData && sfiTitleData && sfiTextData) {
                setTitleGr(servicesTitleData.gr_text);
                setTitleEn(servicesTitleData.en_text);
                setTbiTitleGr(tbiTitleData.gr_text);
                setTbiTitleEn(tbiTitleData.en_text);
                setTbiTextGr(tbiTextData.gr_text);
                setTbiTextEn(tbiTextData.en_text);
                setIenTitleGr(ienTitleData.gr_text);
                setIenTitleEn(ienTitleData.en_text);
                setIenTextGr(ienTextData.gr_text);
                setIenTextEn(ienTextData.en_text);
                setStTitleGr(stTitleData.gr_text);
                setStTitleEn(stTitleData.en_text);
                setStTextGr(stTextData.gr_text);
                setStTextEn(stTextData.en_text);
                setSfiTitleGr(sfiTitleData.gr_text);
                setSfiTitleEn(sfiTitleData.en_text);
                setSfiTextGr(sfiTextData.gr_text);
                setSfiTextEn(sfiTextData.en_text);
            }
        };
        fetchResponses();
    }, []);


    return (
        <div className="home-services-bg-image py-12 md:py-24">
            <div className="max-w-1128 m-auto flex flex-col items-center justify-center gap-8 md:gap-14 px-4 md:px-0">
                <h2 className={`${activeLanguage == 'en' ? 'raisonne-pro-font text-2xl md:text-4xl' : 'open-sans-font text-xl md:text-3xl'}  font-semibold text-neutral-100  text-center`}>
                    <HTMLRenderer htmlContent={activeLanguage == 'en' ? titleEn : titleGr} className=" font-semibold text-neutral-100 " />
                </h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 md:gap-4 w-full">
                    <HomeServicesCard
                        title={activeLanguage === 'el' ? tbiTitleGr : tbiTitleEn}
                        description={activeLanguage === 'el' ? tbiTextGr : tbiTextEn}
                        iconName='extension-puzzle'
                        link='Test Before Invest'
                    />
                    <HomeServicesCard
                        title={activeLanguage === 'el' ? ienTitleGr : ienTitleEn}
                        description={activeLanguage === 'el' ? ienTextGr : ienTextEn}
                        iconName='trending-up'
                        link='Innovation ecosystem and networking'
                    />
                    <HomeServicesCard
                        title={activeLanguage === 'el' ? stTitleGr : stTitleEn}
                        description={activeLanguage === 'el' ? stTextGr : stTextEn}
                        iconName='school'
                        link='Skills and training'
                    />
                    <HomeServicesCard
                        title={activeLanguage === 'el' ? sfiTitleGr : sfiTitleEn}
                        description={activeLanguage === 'el' ? sfiTextGr : sfiTextEn}
                        iconName='briefcase'
                        link='Support to find investment'
                    />
                </div>
            </div>
        </div>
    )
}

export default HomeServices;