import React from 'react';

interface AdimBoxTitleProps {
    title: string;
    secondary_title?: string;
}

const AdimBoxTitle: React.FC<AdimBoxTitleProps> = ({ title, secondary_title }) => {

    return (
        <div className='px-4 mt-4 mb-4  items-center flex justify-between'>
            <div className='text-2xl'>
                {title}
            </div>
            <div className='text-md'>
                {secondary_title}
            </div>
        </div>
    );
};

export default AdimBoxTitle;