import React, { useState, useEffect, useRef } from "react";
import AdminMainHeader from "../AdminMainHeader/AdminMainHeader";
import AdminBox from "../Helpers/AdminBox/AdminBox";
import AdimBoxTitle from "../Helpers/AdminBoxTitle/AdimBoxTitle";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "../../../@/components/ui/select";
import SkeletonLoader from "../../../utils/SkeletonLoader/SkeletonLoader";
import { ACTIVE_URL } from '../../../constants';
import { useAuthContext } from '../../../utils/useAuthContext';
import { partnerType } from "../../../interfaces";
import AdminPartnerRow from "./AdminPrartnersRow/AdminPartnersRow";
import { IonIcon } from "@ionic/react";
import { Button } from '../../../@/components/ui/button';
import AddPartners from "./AddPartners/AddPartners";

const AdminPartners = () => {
    const { authTokens } = useAuthContext();
    const [partnerType, setPartnerType] = useState<string>('consortium');
    const [partnerItems, setPartnerItems] = useState<partnerType[]>([]);
    const [loadingItems, setLoadingItems] = useState<boolean>(true);
    const [view, setView] = useState<string>('overview');

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [itemsPerPage, setItemsPerPage] = useState<number>(5);

    const toggleView = () => {
        if (view === 'overview') {
            setView('add');
        } else {
            setView('overview');
        }
    }
    const fetchResponses = async () => {
        try {
            const response = await fetch(`${ACTIVE_URL}/get_partners/${partnerType}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authTokens?.access}`,
                }
            });
            if (response.ok) {
                const data = await response.json();
                console.log(data);
                setPartnerItems([])
                let temp_arr: any = [];
                data.map((item: any) => {
                    if (item.slug in temp_arr) return;
                    setPartnerItems((prev) => [...prev, {
                        id: item.id,
                        name: item.name,
                        image: item.image,
                        type: item.partner_type,
                        partner_link: item.partner_link,
                        org_type: item.org_type
                    }]);
                    temp_arr.push(item.slug);
                })
                setLoadingItems(false);
            } else {
                console.error('Failed to fetch responses');
            }
        } catch (error) {
            console.error('Error fetching responses:', error);
        }
    }

    useEffect(() => {
        setLoadingItems(true);
        fetchResponses();
    }, [partnerType, view]);

    if (view === 'add') return <AddPartners onComplete={toggleView} />;

    // Calculate the current partner items to display
    const indexOfLastPartnerItem = currentPage * itemsPerPage;
    const indexOfFirstPartnerItem = indexOfLastPartnerItem - itemsPerPage;
    const currentPartnerItems = partnerItems.slice(indexOfFirstPartnerItem, indexOfLastPartnerItem);
    const totalPages = Math.ceil(partnerItems.length / itemsPerPage);

    const goToPreviousPage = () => {
        setCurrentPage((prev) => Math.max(prev - 1, 1));
    }

    const goToNextPage = () => {
        setCurrentPage((prev) => Math.min(prev + 1, totalPages));
    }

    const handleReduceItemsPerPage = () => {
        setItemsPerPage((prev) => Math.max(prev - 1, 1));
    }

    const handleIncreaseItemsPerPage = () => {
        setItemsPerPage((prev) => Math.min(prev + 1, partnerItems.length));
    }

    return (
        <div>
            <AdminMainHeader pageTitle='Partners' />
            <AdminBox>
                <div className="flex w-full justify-between items-center ">
                    <AdimBoxTitle title='Partners Overview' />
                    <div className="mr-2">
                        <Select onValueChange={(option) => setPartnerType(option)} value={partnerType}>
                            <SelectTrigger className="w-[180px] outline-none h-8">
                                <SelectValue placeholder="Select User Group" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectGroup>
                                    <SelectItem key={`consortium`} value={'consortium'} >
                                        Consortium Partners
                                    </SelectItem>
                                    <SelectItem key={`associate`} value={'associate'} >
                                        Associates Partners
                                    </SelectItem>
                                </SelectGroup>
                            </SelectContent>
                        </Select>
                    </div>
                </div>
                
                <div className="w-full px-4 py-4">
                    <div className="w-full flex justify-between items-center select-none">
                        <div className="flex items-center gap-2 text-sm">
                            <button
                                className="py-1 px-2 bg-neutral-200 rounded-md cursor-pointer flex gap-0.5 items-center text-neutral-700 hover:text-neutral-900 hover:shadow-md"
                                onClick={goToPreviousPage}
                                disabled={currentPage === 1}
                            >
                                <IonIcon icon="chevron-back" />
                                <div>Previous</div>
                            </button>
                            <span>{currentPage} out of {totalPages}</span>
                            <button
                                className="py-1 px-2 bg-neutral-200 rounded-md cursor-pointer flex gap-0.5 items-center text-neutral-700 hover:text-neutral-900 hover:shadow-md"
                                onClick={goToNextPage}
                                disabled={currentPage === totalPages}
                            >
                                <div>Next</div>
                                <IonIcon icon="chevron-forward" />
                            </button>
                        </div>
                        <div className="flex items-center justify-center gap-2 text-sm">
                            <div>Items per page:</div>
                            <div
                                className="py-1 w-8 flex items-center justify-center bg-neutral-200 rounded-md cursor-pointer flex gap-0.5 items-center text-neutral-700 hover:text-neutral-900 hover:shadow-md"
                                onClick={handleReduceItemsPerPage}
                            >
                                -
                            </div>
                            <div>
                                {itemsPerPage}
                            </div>
                            <div
                                className="py-1 w-8 flex items-center justify-center bg-neutral-200 rounded-md cursor-pointer flex gap-0.5 items-center text-neutral-700 hover:text-neutral-900 hover:shadow-md"
                                onClick={handleIncreaseItemsPerPage}
                            >
                                +
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-1 mx-2">

                    <div className="flex flex-col gap-2">
                        {currentPartnerItems.map((item, index) => (
                            <div key={item.id}>
                                <AdminPartnerRow org_type={item.org_type} id={item.id} name={item.name} image={item.image} type={item.type} partner_link={item.partner_link} onEdit={fetchResponses} />
                            </div>

                        ))}
                    </div>

                </div>


                <Button variant="outline" className="ml-4 mt-4 px-3 h-9 text-white bg-main hover:bg-main hover:text-white" onClick={toggleView}>
                    <IonIcon icon="add-outline" className="mr-1 text-lg" />
                    Add Partner
                </Button>
            </AdminBox>
        </div>
    )
}

export default AdminPartners;