import React, { useState, useEffect, useRef } from 'react';
import AdminBox from '../Helpers/AdminBox/AdminBox';
import AdminMainHeader from '../AdminMainHeader/AdminMainHeader';
import AdimBoxTitle from '../Helpers/AdminBoxTitle/AdimBoxTitle';
import { Button } from '../../../@/components/ui/button';
import { IonIcon } from '@ionic/react';
import { ACTIVE_URL } from '../../../constants';
import { useAuthContext } from '../../../utils/useAuthContext';
import { serviceItemType } from '../../../interfaces';
import AdminServiceRow from './AdminServiceRow/AdminServiceRow';
import AddService from './AddService/AddService';

const AdminServices = () => {
    const { authTokens } = useAuthContext();
    const [view, setView] = useState<string>('overview');
    const [services, setServices] = useState<serviceItemType[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [itemsPerPage, setItemsPerPage] = useState<number>(5); // Number of services per page
    const fetched = useRef<boolean>(false);
    const [loadingItems, setLoadingItems] = useState<boolean>(true);

    const toggleView = () => {
        if (view === 'overview') {
            setView('add');
        } else {
            setView('overview');
        }
    }

    useEffect(() => {
        const fetchResponses = async () => {
            try {
                const response = await fetch(`${ACTIVE_URL}/get_services/`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authTokens?.access}`,
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    setServices([]);
                    let temp_arr: any = [];
                    data.map((item: any) => {
                        if (item.slug in temp_arr) return;
                        setServices((prev) => [...prev, {
                            id: item.id,
                            partner: item.partner,
                            wp_text: item.wp_text,
                            description: item.service_description,
                            short_description: item.short_description,
                            price: item.price,
                            ben: item.ben,
                        }]);
                        temp_arr.push(item.slug);
                    })
                    setLoadingItems(false);
                } else {
                    console.error('Failed to fetch responses');
                }
            } catch (error) {
                console.error('Error fetching responses:', error);
            }
        }

        if (!fetched.current) {
            fetchResponses();
            fetched.current = true;
        }
    }, [authTokens]);

    // Calculate the current services to display
    const indexOfLastService = currentPage * itemsPerPage;
    const indexOfFirstService = indexOfLastService - itemsPerPage;
    const currentServices = services.slice(indexOfFirstService, indexOfLastService);
    const totalPages = Math.ceil(services.length / itemsPerPage);

    const goToPreviousPage = () => {
        setCurrentPage((prev) => Math.max(prev - 1, 1));
    }

    const goToNextPage = () => {
        setCurrentPage((prev) => Math.min(prev + 1, totalPages));
    }

    const handleReduceItemsPerPage = () => {
        setItemsPerPage((prev) => Math.max(prev - 1, 1));
    }

    const handleIncreaseItemsPerPage = () => {
        setItemsPerPage((prev) => Math.min(prev + 1, services.length));
    }

    return (
        <div>
            <AdminMainHeader pageTitle='Services' />
            <AdminBox>
                {
                    view === 'overview' ?
                        <>

                            <div className="flex w-full justify-between items-center pr-4">
                                <AdimBoxTitle title='Services Overview' />
                                <Button variant="outline" className="px-3 h-9 text-white bg-main hover:bg-blue-600 hover:text-white" onClick={toggleView}>
                                    <IonIcon icon="add-outline" className="mr-1 text-lg" />
                                    Add a Service
                                </Button>
                            </div>
                            <div className="w-full py-4 px-4">
                                <div className="w-full flex justify-between items-center select-none">
                                    <div className="flex items-center gap-2 text-sm">
                                        <button className="py-1 px-2 bg-neutral-200 rounded-md cursor-pointer flex gap-0.5 items-center text-neutral-700 hover:text-neutral-900 hover:shadow-md" onClick={goToPreviousPage} disabled={currentPage === 1}>
                                            <IonIcon icon="chevron-back" />
                                            <div>Previous</div>
                                        </button>
                                        <span>{currentPage} out of {totalPages}</span>
                                        <button className="py-1 px-2 bg-neutral-200 rounded-md cursor-pointer flex gap-0.5 items-center text-neutral-700 hover:text-neutral-900 hover:shadow-md" onClick={goToNextPage} disabled={currentPage === totalPages}>
                                            <div>Next</div>
                                            <IonIcon icon="chevron-forward" />
                                        </button>
                                    </div>
                                    <div className="flex items-center justify-center gap-2 text-sm">
                                        <div>Items per page:</div>
                                        <div
                                            className="py-1 w-8 flex items-center justify-center bg-neutral-200 rounded-md cursor-pointer flex gap-0.5 items-center text-neutral-700 hover:text-neutral-900 hover:shadow-md"
                                            onClick={handleReduceItemsPerPage}
                                        >
                                            -
                                        </div>
                                        <div>
                                            {itemsPerPage}
                                        </div>
                                        <div
                                            className="py-1 w-8 flex items-center justify-center bg-neutral-200 rounded-md cursor-pointer flex gap-0.5 items-center text-neutral-700 hover:text-neutral-900 hover:shadow-md"
                                            onClick={handleIncreaseItemsPerPage}
                                        >
                                            +
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="px-4 flex flex-col gap-2">
                                {currentServices.map((item, index) => (
                                    <div key={index}>
                                        <AdminServiceRow {...item} />
                                    </div>
                                ))}
                            </div>

                            <Button variant="outline" className="ml-4 mt-4 px-3 h-9 text-white bg-main hover:bg-main hover:text-white" onClick={toggleView}>
                                <IonIcon icon="add-outline" className="mr-1 text-lg" />
                                Add Service
                            </Button>
                        </>
                        :
                        <>
                            <AdimBoxTitle title='Add a Service' />
                            <AddService onComplete={toggleView} />
                        </>
                }
            </AdminBox>
        </div>
    )
}

export default AdminServices;
