import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


interface CKEditorComponentProps {
    onChange: (value: string) => void;
    initialValue?: string;
    className?: string;
}



const CKEditorComponent: React.FC<CKEditorComponentProps> = ({ onChange, initialValue, className, ...editorProps }) => {

    const [value, setValue] = useState(initialValue || '');

    useEffect(() => {
        onChange(value || '');
    }, [value]);
    const myColors = [
        "purple",
        "#785412",
        "#452632",
        "#856325",
        "#963254",
        "#254563",
        "#1d4ed8",
        "white",
        "black",

    ];
    const modules = {
        toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ align: ["right", "center", "justify"] }],
            [{ list: "ordered" }, { list: "bullet" }],
            ["link", "image"],
            [{ color: myColors }],
            [{ background: myColors }]
        ]
    };
    
    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "link",
        "color",
        "image",
        "background",
        "align"
    ];
    return (
        <div className={`${className} `}>

            <ReactQuill
                theme="snow"
                modules={modules}
                formats={formats}
                value={value}
                onChange={setValue}
                className='h-full'
            />
        </div>
    );
};

export default CKEditorComponent;