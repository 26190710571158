import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ACTIVE_MEDIA_URL, ACTIVE_URL } from '../../constants';
import { defaultLanguage, TagType } from '../../interfaces';
import greekLanguage from '../../locales/gr.json';
import englishLanguage from '../../locales/en.json';
import HTMLRenderer from '../../utils/HTMLRenderer';
import SkeletonLoader from '../../utils/SkeletonLoader/SkeletonLoader';
import { IonIcon } from '@ionic/react';
import ImagesCarousel from './ImagesCarousel/ImagesCarousel';

type NewsItem = {
    grTitle: string;
    enTitle: string;
    grDescription: string;
    enDescription: string;
    image: string;
    date: string;
    tags: TagType[];
    fileAttachments: { name: string, url: string }[];
    additionalImages: { name: string, url: string }[];
};

const NewsPage: React.FC<defaultLanguage> = ({ activeLanguage }) => {
    const { slug } = useParams();
    const [newsItem, setNewsItem] = useState<NewsItem>({
        grTitle: '',
        enTitle: '',
        grDescription: '',
        enDescription: '',
        image: '',
        date: '',
        tags: [],
        fileAttachments: [],
        additionalImages: []
    });
    const loading = useRef(true);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const fetchResponses = async () => {
            try {
                const response = await fetch(`${ACTIVE_URL}/get_news/${slug}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    console.log(data)
                    const pdfAttachments = data.pdf_attachments.map((pdf: any) => ({
                        name: pdf.media_url.split('/').pop(),
                        url: pdf.media_url
                    }));
                    const additionalImages = data.image_attachments.map((image: any) => ({
                        name: image.media_url.split('/').pop(),
                        url: image.media_url
                    }));

                    setNewsItem({
                        grTitle: data.gr_title,
                        enTitle: data.en_title,
                        grDescription: data.gr_description,
                        enDescription: data.en_description,
                        image: data.image_data,
                        date: data.date,
                        tags: data.tags,
                        fileAttachments: pdfAttachments,
                        additionalImages: additionalImages,
                    });
                    loading.current = false;
                } else {
                    console.error('Failed to fetch responses');
                }
            } catch (error) {
                console.error('Error fetching responses:', error);
            }
        }
        fetchResponses();
    }, [slug]);

    return (
        <div className='pt-[100px]  bg-light_blue px-4 md:px-0'>
            {loading.current ?
                <div className='max-w-1128 m-auto mt-4 md:mt-8'>
                    <SkeletonLoader height={'200px'} width={'100%'} borderRadius={14} />
                </div>
                :
                <div className='text-white mt-4 md:mt-8 max-h-[300px] md:max-h-[400px] h-[40vh] md:h-[54vh] max-w-1128 mx-auto px-4 md:px-10 rounded-xl md:rounded-2xl shadow-md border border-neutral-100 flex items-center justify-center bg-neutral-100'>
                    <img src={`${ACTIVE_MEDIA_URL + newsItem.image}`} alt="" className='h-full w-full object-contain' />
                </div>
            }
            <div className='max-w-1128 m-auto pt-4 md:pt-6 pb-16 md:pb-28 min-h-[40vh]'>
                {loading.current ?
                    <div className='flex flex-col gap-1.5'>
                        <SkeletonLoader height={'16px'} width={'100%'} borderRadius={4} />
                        <SkeletonLoader height={'16px'} width={'100%'} borderRadius={4} />
                        <SkeletonLoader height={'16px'} width={'100%'} borderRadius={4} />
                        <SkeletonLoader height={'16px'} width={'100%'} borderRadius={4} />
                        <SkeletonLoader height={'16px'} width={'100%'} borderRadius={4} />
                        <SkeletonLoader height={'16px'} width={'100%'} borderRadius={4} />
                        <SkeletonLoader height={'16px'} width={'68%'} borderRadius={4} />
                    </div>
                    :
                    <div>
                        <div className='flex flex-col md:flex-row w-full justify-between items-start md:items-center mb-2 md:mb-4'>
                            <h3 className='text-2xl md:text-4xl select-none mb-2 md:mb-0'>{newsItem.enTitle}</h3>
                            <p className='text-sm md:text-base select-none'>{newsItem?.date ? new Date(newsItem.date).toLocaleDateString('en-GB') : ''}</p>
                        </div>
                        <div className='flex flex-wrap gap-1 mb-4'>
                            {newsItem.tags.map((tag, index) => (
                                <Link to={'/news?tags=' + tag.name} key={index} className='bg-main text-white text-xs md:text-sm px-1.5 py-0.5 rounded-md select-none cursor-pointer'>{tag.name}</Link>
                            ))}
                        </div>
                        <HTMLRenderer className='text-base md:text-lg' htmlContent={activeLanguage === 'GR' ? newsItem?.grDescription : newsItem?.enDescription} />
                        <div className='mt-4 md:mt-6'>
                        {newsItem.fileAttachments.length > 0 && (
                                <div>
                                    <div className='flex flex-col gap-2.5'>
                                        {newsItem.fileAttachments.map((file, index) => (
                                            <div key={index} className=''>
                                                <a href={`${ACTIVE_MEDIA_URL}${file.url}`} target="_blank" rel="noopener noreferrer" className='flex w-fit items-center justify-center gap-0.5 text-main hover:underline bg-white px-2 md:px-2.5 py-1 rounded-md border border-main text-sm md:text-base'>
                                                    <IonIcon icon='document' className='text-main' />
                                                    <div>
                                                        {file.name}
                                                    </div>
                                                </a>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                            {newsItem.additionalImages.length > 0 && (
                                <div className='mt-2'>
                                    <ImagesCarousel images={newsItem.additionalImages.map(image => image.url)} />
                                </div>
                            )}

                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default NewsPage;
