import React, { ReactNode } from 'react';
import './Modal.css';

interface ModalProps {
    show: boolean;
    onClose: () => void;
    children: ReactNode;
    titleIcon?: ReactNode;
    titleText?: string;
    width?: string;
}

const Modal: React.FC<ModalProps> = ({ show, onClose, children, titleIcon, titleText, width }) => {
    const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    if (!show) {
        return null;
    }

    return (
        <div className="modal relative" onClick={handleOverlayClick}>
            <div className={`${width} rounded-md bg-white shadow-md relative `}>
                <div className="rounded-t-md px-6 py-4 text-white bg-main flex items-center justify-between">
                    <div className="">{titleIcon} {titleText} </div>
                    <span className="text-xl cursor-pointer" onClick={onClose}>&times;</span>
                </div>
                <div className="">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Modal;