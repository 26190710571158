import AdminMainHeader from "../AdminMainHeader/AdminMainHeader";
import SiteTextEditor from "./SiteTextEditor/SiteTextEditor";


const AdminSiteTexts = () => {


    return (
        <div>
            <AdminMainHeader pageTitle='Site Texts' />
            <div className="text-2xl py-4 text-main">Footer Texts</div>
            <SiteTextEditor sectionTitle='Footer Title' get_text_url='/texts/get/footer_title' post_text_url='/texts/post/footer_title' />
            <SiteTextEditor sectionTitle='Footer EU Text' get_text_url='/texts/get/footer_eu_text' post_text_url='/texts/post/footer_eu_text' />
            <SiteTextEditor sectionTitle='Footer Location' get_text_url='/texts/get/footer_location' post_text_url='/texts/post/footer_location' />
            <div className="text-2xl py-4 text-main">EDIH Network Page Texts</div>
            <SiteTextEditor sectionTitle='EDIH Network Title' get_text_url='/texts/get/edih_network_title' post_text_url='/texts/post/edih_network_title'  />
            <SiteTextEditor sectionTitle='EDIH Network Text' get_text_url='/texts/get/edih_network_text' post_text_url='/texts/post/edih_network_text' />
            <div className="text-2xl py-4 text-main">Consortium Partners Page Texts</div>
            <SiteTextEditor sectionTitle='Consortium Partners Title' get_text_url='/texts/get/cons_part_title' post_text_url='/texts/post/cons_part_title' />
            <SiteTextEditor sectionTitle='Consortium Partners' get_text_url='/texts/get/const_part_text' post_text_url='/texts/post/const_part_text' />
            <div className="text-2xl py-4 text-main">Associate Partners Page Texts</div>
            <SiteTextEditor sectionTitle='Associate Partners Title' get_text_url='/texts/get/ass_part_title' post_text_url='/texts/post/ass_part_title' />
            <SiteTextEditor sectionTitle='Associate Partners' get_text_url='/texts/get/ass_part_text' post_text_url='/texts/post/ass_part_text' />
            <div className="text-2xl py-4 text-main">Application Areas Page Texts</div>
            <SiteTextEditor sectionTitle='Application Areas Page Energy & Environment Text' get_text_url='/texts/get/aa_page_ee_text' post_text_url='/texts/post/aa_page_ee_text' />
            <SiteTextEditor sectionTitle='Application Areas Page Supply Chain & Mobility Text' get_text_url='/texts/get/aa_page_scm_text' post_text_url='/texts/post/aa_page_scm_text' />
            <SiteTextEditor sectionTitle='Application Areas Page CT Text' get_text_url='/texts/get/aa_page_ct_text' post_text_url='/texts/post/aa_page_ct_text' />
            <div className="text-2xl py-4 text-main">Services Page</div>
            <SiteTextEditor sectionTitle='Services Page Title' get_text_url='/texts/get/services_page_title' post_text_url='/texts/post/services_page_title' />
            <SiteTextEditor sectionTitle='Services Page Text' get_text_url='/texts/get/services_page_text' post_text_url='/texts/post/services_page_text' />
            <div className="text-2xl py-4 text-main">Home Texts</div>
            <SiteTextEditor sectionTitle='Home Project Title' get_text_url='/texts/get/home_project_title' post_text_url='/texts/post/home_project_title' />
            <SiteTextEditor sectionTitle='Home Project Text' get_text_url='/texts/get/home_project_text' post_text_url='/texts/post/home_project_text' />
            <SiteTextEditor sectionTitle='Home Application Areas Title' get_text_url='/texts/get/home_aa_title' post_text_url='/texts/post/home_aa_title' />
            <SiteTextEditor sectionTitle='Home Application Areas Energy & Environment Title' get_text_url='/texts/get/home_aa_ee_title' post_text_url='/texts/post/home_aa_ee_title' />
            <SiteTextEditor sectionTitle='Home Application Areas Energy & Environment Text' get_text_url='/texts/get/home_aa_ee_text' post_text_url='/texts/post/home_aa_ee_text' />
            <SiteTextEditor sectionTitle='Home Application Areas Supply Chain & Mobility Title' get_text_url='/texts/get/home_aa_scm_title' post_text_url='/texts/post/home_aa_scm_title' />
            <SiteTextEditor sectionTitle='Home Application Areas Supply Chain & Mobility Text' get_text_url='/texts/get/home_aa_scm_text' post_text_url='/texts/post/home_aa_scm_text' />
            <SiteTextEditor sectionTitle='Home Application Areas CT Title' get_text_url='/texts/get/home_aa_ct_title' post_text_url='/texts/post/home_aa_ct_title' />
            <SiteTextEditor sectionTitle='Home Application Areas CT Text' get_text_url='/texts/get/home_aa_ct_text' post_text_url='/texts/post/home_aa_ct_text' />
            <SiteTextEditor sectionTitle='Home Expertise Title' get_text_url='/texts/get/home_exp_title' post_text_url='/texts/post/home_exp_title' />
            <SiteTextEditor sectionTitle='Home Expertise Text' get_text_url='/texts/get/home_exp_text' post_text_url='/texts/post/home_exp_text' />
            <SiteTextEditor sectionTitle='Home Expertise AI' get_text_url='/texts/get/home_exp_ai' post_text_url='/texts/post/home_exp_ai' />
            <SiteTextEditor sectionTitle='Home Expertise High-Performance Computing' get_text_url='/texts/get/home_exp_hpc' post_text_url='/texts/post/home_exp_hpc' />
            <SiteTextEditor sectionTitle='Home Expertise Cybersecurity' get_text_url='/texts/get/home_exp_cs' post_text_url='/texts/post/home_exp_cs' />
            <SiteTextEditor sectionTitle='Home Services Title' get_text_url='/texts/get/home_ser_title' post_text_url='/texts/post/home_ser_title' />
            <SiteTextEditor sectionTitle='Home Services Test Before Invest Title' get_text_url='/texts/get/home_ser_tbi_title' post_text_url='/texts/post/home_ser_tbi_title' />
            <SiteTextEditor sectionTitle='Home Services Test Before Invest Text' get_text_url='/texts/get/home_ser_tbi_text' post_text_url='/texts/post/home_ser_tbi_text' />
            <SiteTextEditor sectionTitle='Home Services IEN Title' get_text_url='/texts/get/home_ser_ien_title' post_text_url='/texts/post/home_ser_ien_title' />
            <SiteTextEditor sectionTitle='Home Services IEN Text' get_text_url='/texts/get/home_ser_ien_text' post_text_url='/texts/post/home_ser_ien_text' />
            <SiteTextEditor sectionTitle='Home Services ST Title' get_text_url='/texts/get/home_ser_st_title' post_text_url='/texts/post/home_ser_st_title' />
            <SiteTextEditor sectionTitle='Home Services ST Text' get_text_url='/texts/get/home_ser_st_text' post_text_url='/texts/post/home_ser_st_text' />
            <SiteTextEditor sectionTitle='Home Services SFI Title' get_text_url='/texts/get/home_ser_sfi_title' post_text_url='/texts/post/home_ser_sfi_title' />
            <SiteTextEditor sectionTitle='Home Services Support Text' get_text_url='/texts/get/home_ser_sfi_text' post_text_url='/texts/post/home_ser_sfi_text' />
            <SiteTextEditor sectionTitle='Home News Title' get_text_url='/texts/get/home_news_title' post_text_url='/texts/post/home_news_title' />
            <SiteTextEditor sectionTitle='Home News View All' get_text_url='/texts/get/home_news_view_all' post_text_url='/texts/post/home_news_view_all' />
        </div>
    )
}


export default AdminSiteTexts;
