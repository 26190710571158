import React, { useState, useEffect } from 'react';
import CKEditorComponent from '../../../../utils/QuillEditor/QuillEditor';
import { useAuthContext } from '../../../../utils/useAuthContext';
import { ACTIVE_URL } from '../../../../constants';
import { Input } from '../../../../@/components/ui/input';
import { IonIcon } from '@ionic/react';
import { DatePicker } from '../../Helpers/SimpleDatePicker/SimpleDatePicker';
import { TagType } from '../../../../interfaces';
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../../../../@/components/ui/select";
import NewsTagEditSection from '../NewsTagEditSection/NewsTagEditSection';

interface AddNewsProps {
    onComplete: () => void;
}

const AddNews: React.FC<AddNewsProps> = ({ onComplete }) => {
    const { authTokens } = useAuthContext();
    const [grTitle, setGrTitle] = useState<string>('');
    const [enTitle, setEnTitle] = useState<string>('');
    const [grDescription, setGrDescription] = useState<string>('');
    const [enDescription, setEnDescription] = useState<string>('');
    const [slug, setSlug] = useState<string>('');
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [selectedPDFs, setSelectedPDFs] = useState<File[]>([]);
    const [additionalImages, setAdditionalImages] = useState<File[]>([]);
    const [shortDescriptionGr, setShortDescriptionGr] = useState<string>('');
    const [shortDescriptionEn, setShortDescriptionEn] = useState<string>('');
    const [tags, setTags] = useState<TagType[]>([]);
    const [selectedTags, setSelectedTags] = useState<TagType[]>([]);
    const [tagToBeAdded, setTagToBeAdded] = useState<TagType>({} as TagType);
    const [date, setDate] = useState<string>('');

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            const selected = files[0];
            if (selected.type.startsWith('image/')) {
                setSelectedFile(selected);
            } else {
                console.log('Invalid file type. Please select an image.');
            }
        }
    };

    const handlePDFChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
            const pdfs = Array.from(files).filter(file => file.type === 'application/pdf');
            setSelectedPDFs(pdfs);
        }
    };

    const handleAdditionalImagesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
            const images = Array.from(files).filter(file => file.type.startsWith('image/'));
            setAdditionalImages(images);
        }
    };

    const handleTagSelectionChange = (selectedId: string | number) => {
        const selectedTag = tags.find(tag => tag.id === selectedId);
        if (selectedTag) {
            setTagToBeAdded(selectedTag);
        }
    };

    const handleAddToSelectedTags = () => {
        if (tagToBeAdded.id) {
            setSelectedTags([...selectedTags, tagToBeAdded]);
        }
    }

    useEffect(() => {
        const fetchResponses = async () => {
            if (!authTokens || !authTokens.access) {
                return;
            }
            try {
                const response = await fetch(`${ACTIVE_URL}/get_all_tags/`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authTokens.access}`,
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    setTags(data);
                } else {
                    console.error('Failed to fetch responses');
                }
            } catch (error) {
                console.error('Error fetching responses:', error);
            }
        };
        fetchResponses();
    }, [authTokens]);

    const unselectTag = (tagId: string) => {
        const newTags = selectedTags.filter((tag) => tag.id !== tagId);
        setSelectedTags(newTags);
    }

    const submitNewNews = async () => {
        if (!authTokens || !authTokens.access) {
            return;
        }
        try {
            const formData = new FormData();
            const tagIds = selectedTags.map(tag => tag.id).join(',');
            formData.append('tags', tagIds);
            formData.append('gr_title', grTitle);
            formData.append('en_title', enTitle);
            formData.append('gr_description', grDescription);
            formData.append('en_description', enDescription);
            formData.append('short_description_gr', shortDescriptionGr);
            formData.append('short_description_en', shortDescriptionEn);
            formData.append('date', date);
            formData.append('slug', slug);

            if (selectedFile) {
                formData.append('image', selectedFile);
            }

            selectedPDFs.forEach(pdf => {
                formData.append('pdfs', pdf);
            });

            additionalImages.forEach(image => {
                formData.append('additional_images', image);
            });

            const response = await fetch(`${ACTIVE_URL}/post_news/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${authTokens.access}`,
                },
                body: formData,
            });

            if (response.ok) {
                console.log('News posted successfully');
                setGrTitle('');
                setEnTitle('');
                setGrDescription('');
                setEnDescription('');
                setDate('');
                setSelectedFile(null);
                setSelectedPDFs([]);
                setAdditionalImages([]);
                selectedTags.forEach((tag) => unselectTag(tag.id));
                onComplete();
            } else {
                console.error('Failed to post news');
            }
        } catch (error) {
            console.error('Error posting news:', error);
        }
    }

    return (
        <div className='px-4 flex flex-col gap-4'>
            <div className='flex flex-col mt-6 gap-2 mb-4'>
                <label className='text-lg text-neutral-800 ' htmlFor="desc_en">Displayed Date</label>
                <DatePicker onDateChange={setDate} />
            </div>
            <div className='flex flex-col gap-2'>
                <label className='text-lg text-neutral-800 ' htmlFor="slug">Slug Address</label>
                <input className=' outline-none border-b border-b-neutral-300' type="text" id="slug" value={slug} onChange={(e) => setSlug(e.target.value)} />
            </div>
            <div className='flex flex-col gap-2'>
                <label className='text-lg text-neutral-800' htmlFor="picture">Display Image</label>
                <Input id="picture" type="file" onChange={handleFileChange} accept="image/jpeg, image/jpg, image/png, image/gif" />
            </div>
            <div className='flex flex-col gap-2'>
                <label className='text-lg text-neutral-800' htmlFor="pdfs">Upload PDFs</label>
                <Input id="pdfs" type="file" onChange={handlePDFChange} accept="application/pdf" multiple />
            </div>
            <div className='flex flex-col gap-2'>
                <label className='text-lg text-neutral-800' htmlFor="additional_images">Additional Images</label>
                <Input id="additional_images" type="file" onChange={handleAdditionalImagesChange} accept="image/jpeg, image/jpg, image/png, image/gif" multiple />
            </div>
            <div className='flex flex-col gap-2'>
                <label className='text-lg text-neutral-800' htmlFor="title_gr">Greek Title</label>
                <input className=' outline-none border-b border-b-neutral-300' type="text" id="title_gr" value={grTitle} onChange={(e) => setGrTitle(e.target.value)} />
            </div>
            <div className='flex flex-col gap-2'>
                <label className='text-lg text-neutral-800' htmlFor="title_en">English Title</label>
                <input className=' outline-none border-b border-b-neutral-300' type="text" id="title_en" value={enTitle} onChange={(e) => setEnTitle(e.target.value)} />
            </div>
            <div className={`flex flex-col gap-2 mb-4`}>
                <label className='text-lg text-neutral-800' htmlFor="desc_short_gr">Greek Short Description</label>
                <textarea className=' outline-none border p-1 rounded-md shadow-sm border-b-300' id="desc_short_gr" maxLength={255} value={shortDescriptionGr} onChange={(e) => setShortDescriptionGr(e.target.value)} />
            </div>
            <div className={`flex flex-col gap-2 mb-4`}>
                <label className='text-lg text-neutral-800' htmlFor="desc_short_en">English Short Description</label>
                <textarea className=' outline-none border p-1 rounded-md shadow-sm border-b-300' id="desc_short_en" maxLength={255} value={shortDescriptionEn} onChange={(e) => setShortDescriptionEn(e.target.value)} />
            </div>
            <div className={`flex flex-col gap-2 mb-4`}>
                <label className='text-lg text-neutral-800' htmlFor="desc_gr">Greek Page Description</label>
                <CKEditorComponent
                    className='h-40'
                    initialValue={grDescription}
                    onChange={(value) => setGrDescription(value)}
                />
            </div>
            <div className='flex flex-col mt-6 gap-2 mb-4'>
                <label className='text-lg text-neutral-800 ' htmlFor="desc_en">English Page Description</label>
                <CKEditorComponent
                    className='h-40'
                    initialValue={enDescription}
                    onChange={(value) => setEnDescription(value)}
                />
            </div>

            <NewsTagEditSection tags={tags} selectedTags={selectedTags} handleAddToSelectedTags={handleAddToSelectedTags} handleTagSelectionChange={handleTagSelectionChange} unselectTag={unselectTag} />

            <div className='mt-5'>
                <button onClick={submitNewNews} className="flex items-center px-4 py-2 bg-main text-white cursor-pointer mt-1 text-m font-semibold rounded transition-colors shadow-md focus:outline-none">
                    <IonIcon icon="add-outline" className="mr-1 text-lg" />
                    Add News
                </button>
            </div>
        </div>
    )
}

export default AddNews;
