import React, { useState, useEffect } from "react";
import { defaultLanguage } from "../../interfaces";
import edihNetworkImage from '../../assets/images/edih_network.jpg';
import { useLocation } from 'react-router-dom';
import fetchSiteText from "../../utils/GetSiteText/GetSiteText";
import HTMLRenderer from "../../utils/HTMLRenderer";

const EDIHNetworkPage: React.FC<defaultLanguage> = ({ activeLanguage }) => {
    const location = useLocation();

    const [edihTitleGr, setEdihTitleGr] = useState<string>('');
    const [edihTitleEn, setEdihTitleEn] = useState<string>('');
    const [edihTextGr, setEdihTextGr] = useState<string>('');
    const [edihTextEn, setEdihTextEn] = useState<string>('');

    useEffect(() => {
        const fetchResponses = async () => {
            const edihTitle = await fetchSiteText('edih_network_title');
            const edihText = await fetchSiteText('edih_network_text');

            if (edihTitle && edihText) {
                setEdihTitleGr(edihTitle.gr_text);
                setEdihTitleEn(edihTitle.en_text);
                setEdihTextGr(edihText.gr_text);
                setEdihTextEn(edihText.en_text);
            }
        };
        fetchResponses();
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="w-full flex items-center justify-center mb-10 mt-[100px] px-2 md:px-0">
            <div className="max-w-1128 m-auto w-full bg-main h-full rounded-md text-neutral-200 flex flex-col items-center p-8">
                <h2 className="text-2xl md:text-3xl mt-3 md:mt-5 text-center raisonne-pro-font">
                    <HTMLRenderer htmlContent={activeLanguage === 'en' ? edihTitleEn : edihTitleGr} />
                </h2>
                <div className="flex flex-col md:grid md:grid-cols-3 h-full w-full pt-4">
                    <img src={edihNetworkImage} alt="" className="rounded-md"/>

                    <div className="md:col-span-2 p-2 md:p-5 h-full flex md:items-center">
                        <div className="flex flex-col gap-4">
                            {/* <h3 className="mt-2 md:mt-5 text-xl md:text-2xl">
                                <HTMLRenderer htmlContent={activeLanguage === 'en' ? edihTitleEn : edihTitleGr} />
                            </h3> */}
                            <HTMLRenderer htmlContent={activeLanguage === 'en' ? edihTextEn : edihTextGr} className="text-sm md:text-base text-white" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EDIHNetworkPage;