import React, { useEffect, useMemo, useRef, useState } from 'react';
import { TagType, defaultLanguage } from "../../../../interfaces";
import greekLanguage from '../../../../locales/gr.json';
import englishLanguage from '../../../../locales/en.json';
import { ACTIVE_URL } from '../../../../constants';
import HomeNewsItem from '../../../Home/HomeNews/HomeNewsItem/HomeNewsItem';
import { IonIcon } from '@ionic/react';
import { useSearchParams } from 'react-router-dom';
import fetchSiteText from '../../../../utils/GetSiteText/GetSiteText';
import HTMLRenderer from '../../../../utils/HTMLRenderer';

type NewsItem = {
    grTitle: string;
    enTitle: string;
    grDescription: string;
    enDescription: string;
    image: string;
    slug: string;
    date: string;
    tags: TagType[];
};

const NewsAllNews: React.FC<defaultLanguage> = ({ activeLanguage, className }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [newsItems, setNewsItems] = useState<NewsItem[]>([]);
    const fetched = useRef<boolean>(false);
    const [loadingItems, setLoadingItems] = useState<boolean>(true);
    const languageData = activeLanguage === 'el' ? greekLanguage : englishLanguage;
    const [headerTextGr, setHeaderTextGr] = useState<string>('');
    const [headerTextEn, setHeaderTextEn] = useState<string>('');

    useEffect(() => {
        const fetchResponses = async () => {
            const newsTitleTextData = await fetchSiteText('home_news_title');
            if (newsTitleTextData) {
                setHeaderTextGr(newsTitleTextData?.gr_text);
                setHeaderTextEn(newsTitleTextData?.en_text);
            }
        };
        fetchResponses();
    }, []);

    const [currentFilter, setCurrentFilter] = useState<string>('descending_date');

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [itemsPerPage, setItemsPerPage] = useState<number>(9);

    const [searchQuery, setSearchQuery] = useState<string>('');
    const [selectedTag, setSelectedTag] = useState<string | undefined>(undefined);

    useEffect(() => {
        const pageParam = searchParams.get('page');
        setCurrentPage(pageParam ? parseInt(pageParam, 10) : 1);
        
        const queryParam = searchParams.get('query');
        setSearchQuery(queryParam || '');
        
        const tagParam = searchParams.get('tags');
        setSelectedTag(tagParam || undefined);

        const fetchResponses = async () => {
            try {
                const response = await fetch(`${ACTIVE_URL}/get_all_news/`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    setNewsItems(data.map((item: any) => ({
                        grTitle: item.gr_title,
                        enTitle: item.en_title,
                        grDescription: item.gr_description,
                        enDescription: item.en_description,
                        image: item.image_data,
                        date: item.date,
                        slug: item.slug,
                        tags: item.tags,
                    })));
                    setLoadingItems(false);
                } else {
                    console.error('Failed to fetch responses');
                }
            } catch (error) {
                console.error('Error fetching responses:', error);
            }
        }
        if (!fetched.current) {
            fetchResponses();
            fetched.current = true;
        }
    }, [searchParams]);

    const filteredItems = newsItems.filter(item => {
        const matchesSearchQuery = searchQuery ? (
            item.grTitle.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.enTitle.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.grDescription.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.enDescription.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.slug.toLowerCase().includes(searchQuery.toLowerCase())
        ) : true;

        const matchesTag = selectedTag ? item.tags.some(tag => tag.name === selectedTag) : true;

        return matchesSearchQuery && matchesTag;
    });

    const handleTagClick = (tagName: string) => {
        const newTag = selectedTag === tagName ? undefined : tagName;
        setSelectedTag(newTag);
        const params = new URLSearchParams(searchParams);
        if (newTag) {
            params.set('tags', newTag);
        } else {
            params.delete('tags');
        }
        if (searchQuery) {
            params.set('query', searchQuery);
        } else {
            params.delete('query');
        }
        params.set('page', '1');
        setSearchParams(params);
        setCurrentPage(1);
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newQuery = e.target.value;
        setSearchQuery(newQuery);
        const params = new URLSearchParams(searchParams);
        if (newQuery) {
            params.set('query', newQuery);
        } else {
            params.delete('query');
        }
        if (selectedTag) {
            params.set('tags', selectedTag);
        } else {
            params.delete('tags');
        }
        params.set('page', '1');
        setSearchParams(params);
        setCurrentPage(1);
    };

    useEffect(() => {
        const tempArr: string[] = [];
        newsItems.forEach((item, index) => {
            if (index === newsItems.length - 1) {
                if (tempArr.includes(item.slug)) {
                    setNewsItems(prev => prev.slice(0, -1));
                } else {
                    tempArr.push(item.slug);
                }
            }
        });
    }, [newsItems]);

    const sortItemsByDateAscending = () => {
        const sortedItems = [...newsItems].sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
        setNewsItems(sortedItems);
    };

    const sortItemsByDateDescending = () => {
        const sortedItems = [...newsItems].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
        setNewsItems(sortedItems);
    };

    useEffect(() => {
        if (currentFilter === 'ascending_date') sortItemsByDateDescending();
        else if (currentFilter === 'descending_date') sortItemsByDateAscending();
    }, [currentFilter]);

    const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
    const lastItemIndex = currentPage * itemsPerPage;
    const firstItemIndex = lastItemIndex - itemsPerPage;
    const currentItems = filteredItems.slice(firstItemIndex, lastItemIndex);

    const updatePageInUrl = (newPage: number) => {
        const params = new URLSearchParams(searchParams);
        params.set('page', newPage.toString());
        setSearchParams(params);
    };

    const nextPage = () => {
        const newPage = Math.min(currentPage + 1, totalPages);
        setCurrentPage(newPage);
        updatePageInUrl(newPage);
    };

    const prevPage = () => {
        const newPage = Math.max(currentPage - 1, 1);
        setCurrentPage(newPage);
        updatePageInUrl(newPage);
    };

    const uniqueTags = useMemo(() => 
        Array.from(new Set(newsItems.flatMap(newsItem => newsItem.tags.map(tag => tag.name)))),
        [newsItems]
    );
    
    return (
        <div className={`max-w-1128 m-auto pb-10 md:pb-20 select-none ${className} px-4 md:px-0`}>
            <div className='flex flex-col md:flex-row w-full justify-between items-center mb-6 md:mb-12 gap-4 md:gap-0'>
                <h3 className='text-2xl md:text-3xl text-neutral-800 text-center md:text-left'>
                    <h1 className="text-main text-2xl md:text-3xl font-semibold">
                        <HTMLRenderer htmlContent={activeLanguage == 'en' ? headerTextEn : headerTextGr} className="py-2 md:py-4 text-base md:text-xl text-main" />
                    </h1>
                </h3>
                <div className='px-4 py-2 w-full md:w-[300px] max-w-full md:max-w-1/3 rounded-full bg-white shadow-md flex items-center justify-center'>
                    <div className='flex items-center w-[10%] border-r'>
                        <IonIcon icon='search' className='text-neutral-500' />
                    </div>
                    <div className='w-[90%] pl-[5%] pr-[2%]'>
                        <input
                            type="text"
                            placeholder='Search by keywords'
                            className='w-full outline-none border-none'
                            value={searchQuery}
                            onChange={handleSearchChange}
                        />
                    </div>
                </div>
            </div>
            {selectedTag && (
                <div className='flex flex-wrap gap-1 mb-4'>
                    {uniqueTags.map((tagName) => (
                        <div
                            key={tagName}
                            className={`bg-main text-white text-xs md:text-sm px-1.5 py-0.5 rounded-md select-none cursor-pointer ${selectedTag === tagName ? 'bg-opacity-70' : ''}`}
                            onClick={() => handleTagClick(tagName)}
                        >
                            {tagName}
                        </div>
                    ))}
                </div>
            )}
            <div className="flex items-center gap-2 text-xs md:text-sm justify-center md:justify-end pb-4 md:pb-6">
                <button className="py-1 px-2 bg-neutral-200 rounded-md cursor-pointer flex gap-0.5 items-center text-neutral-700 hover:text-neutral-900 hover:shadow-md" onClick={prevPage} disabled={currentPage === 1}>
                    <IonIcon icon="chevron-back" />
                    <div>Previous</div>
                </button>
                <span>{currentPage} out of {totalPages}</span>
                <button className="py-1 px-2 bg-neutral-200 rounded-md cursor-pointer flex gap-0.5 items-center text-neutral-700 hover:text-neutral-900 hover:shadow-md" onClick={nextPage} disabled={currentPage === totalPages}>
                    <div>Next</div>
                    <IonIcon icon="chevron-forward" />
                </button>
            </div>
            {currentItems.length > 0 ? (
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-4 md:gap-x-5 gap-y-8 md:gap-y-12">
                    {currentItems.map((item, index) => (
                        <div key={index}>
                            <HomeNewsItem
                                loading={loadingItems}
                                grTitle={item.grTitle}
                                enTitle={item.enTitle}
                                grDescription={item.grDescription}
                                enDescription={item.enDescription}
                                image={item.image}
                                slug={item.slug}
                                date={item.date}
                                tags={item.tags}
                                activeLanguage={activeLanguage}
                            />
                        </div>
                    ))}
                </div>
            ) : (
                <div className="text-center text-base md:text-lg text-neutral-500 py-20 md:py-40">
                    No news items match
                    {searchQuery && selectedTag && ` the keyword '${searchQuery}' and the tag '${selectedTag}'`}
                    {!searchQuery && selectedTag && ` the tag '${selectedTag}'`}
                    {searchQuery && !selectedTag && ` the keyword '${searchQuery}'`}
                    {!searchQuery && !selectedTag && ` your criteria`}.
                </div>
            )}
            <div className="flex items-center gap-2 text-xs md:text-sm justify-center md:justify-end pt-4">
                <button className="py-1 px-2 bg-neutral-200 rounded-md cursor-pointer flex gap-0.5 items-center text-neutral-700 hover:text-neutral-900 hover:shadow-md" onClick={prevPage} disabled={currentPage === 1}>
                    <IonIcon icon="chevron-back" />
                    <div>Previous</div>
                </button>
                <span>{currentPage} out of {totalPages}</span>
                <button className="py-1 px-2 bg-neutral-200 rounded-md cursor-pointer flex gap-0.5 items-center text-neutral-700 hover:text-neutral-900 hover:shadow-md" onClick={nextPage} disabled={currentPage === totalPages}>
                    <div>Next</div>
                    <IonIcon icon="chevron-forward" />
                </button>
            </div>
        </div>
    );
};

export default NewsAllNews;