import React, { useState, useEffect } from 'react';
import { useAuthContext } from '../../../../utils/useAuthContext';
import AdimBoxTitle from '../../Helpers/AdminBoxTitle/AdimBoxTitle';
import AdminBox from '../../Helpers/AdminBox/AdminBox';
import TagItem from './TagItem/TagItem';
import { ACTIVE_URL } from '../../../../constants';
import { Button } from '../../../../@/components/ui/button';
import { IonIcon } from '@ionic/react';

type TagType = {
    id: string;
    name: string;
}

const AdminSettingsTagManagement = () => {
    const { authTokens } = useAuthContext();
    const [tags, setTags] = useState<TagType[]>([]);
    const [view, setView] = useState<string>('menu');
    const [newTag, setNewTag] = useState('');

    const toggleView = () => {
        if (view === 'menu') {
            setView('add');
        } else {
            setView('menu');
        }
    }

    useEffect(() => {
        const fetchResponses = async () => {
            if (!authTokens || !authTokens.access) {
                return;
            }
            try {
                const response = await fetch(`${ACTIVE_URL}/get_all_tags/`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authTokens.access}`,
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    setTags(data);
                } else {
                    console.error('Failed to fetch responses');
                }
            } catch (error) {
                console.error('Error fetching responses:', error);
            }
        };

        fetchResponses();
    }, []);

    const handleSaveTag = async () => {
        if (!authTokens || !authTokens.access) {
            return;
        }
        if (newTag !== '') {
            try {
                const response = await fetch(`${ACTIVE_URL}/add_tag/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authTokens.access}`,
                    },
                    body: JSON.stringify({ tag_name: newTag })
                });

                if (response.ok) {
                    const tagData = await response.json();
                    setTags([...tags, tagData]);
                    console.log('Tag created successfully');
                    setNewTag('');
                } else {
                    console.error('Failed to create the tag');
                }
            } catch (error) {
                console.error('Error creating the tag:', error);
            }
        }
    }

    const handleDeleteTag = async (tagId: string) => {
        if (!authTokens || !authTokens.access) {
            return;
        }

        try {
            const response = await fetch(`${ACTIVE_URL}/delete_tag/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authTokens.access}`,
                },
                body: JSON.stringify({ id: tagId })
            });

            if (response.ok) {
                // Remove the deleted tag from the state
                setTags(tags.filter((tag) => tag.id !== tagId));
                console.log('Tag deleted successfully');
            } else {
                console.error('Failed to delete the tag');
            }
        } catch (error) {
            console.error('Error deleting the tag:', error);
        }
    }

    return (
        <div className=''>
            <AdminBox>
                <AdimBoxTitle title='Tag Management' />
                <div className='pt-2 flex gap-4 px-4'>
                    {view === 'menu' && (
                        <div onClick={toggleView}>
                            <Button variant="outline" className='px-3 h-9 bg-main text-white hover:text-white hover:bg-blue-500'><IonIcon icon='add' className='text-lg'></IonIcon> Add Tag</Button>
                        </div>
                    )}
                    {view === 'add' && (
                        <div onClick={toggleView}>
                            <Button variant="outline" className='px-3 h-9 '><IonIcon icon='remove-outline' className='text-lg mr-1'></IonIcon> Close</Button>
                        </div>
                    )}
                </div>
                {view === 'add' && (
                    <div className='px-4 pt-4 pb-2'>
                        <input
                            type="text"
                            placeholder="Tag Name"
                            value={newTag}
                            className='border rounded-md px-2 h-10 focus:outline-none mr-3'
                            onChange={(e) => setNewTag(e.target.value)}
                        />
                        <Button variant="outline" onClick={handleSaveTag}>
                            Save Tag
                        </Button>
                    </div>
                )}
                <div className='m-4 p-4 border rounded-md flex flex-wrap gap-1'>
                    {tags.map((tag: any) => (
                        <TagItem key={tag.id} tag={tag.name} tagId={tag.id} onDelete={() => handleDeleteTag(tag.id)} />
                    ))}
                </div>
            </AdminBox>
        </div>
    )
};

export default AdminSettingsTagManagement;