import React, { useEffect, useState } from 'react';
import { defaultLanguage } from '../../interfaces';
import NewsHomeHeader from '../../Components/Misc/News/NewsHomeHeader/NewsHomeHeader';
import NewsAllNews from '../../Components/Misc/News/NewsAllNews/NewsAllNews';

const NewsHome:React.FC<defaultLanguage> = ({activeLanguage}) => {

    return (
        <div className='bg-light_blue pt-[140px]'>
            {/* <NewsHomeHeader activeLanguage={activeLanguage} /> */}
            <NewsAllNews activeLanguage={activeLanguage}  />
        </div>
    )
}

export default NewsHome;