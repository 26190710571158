import { IonIcon } from "@ionic/react";
import { defaultLanguage } from "../../../interfaces";
import homeIntroBg from "../../../assets/images/home_pattern_bg.png";
import greenBg from "../../../assets/images/green_wp.jpg";
import blueBg from "../../../assets/images/application_areas/3.jpg";
import redBgEmpty from "../../../assets/images/application_areas/2.jpg";
import greenBgEmpty from "../../../assets/images/application_areas/1.jpg";
import aiIcon from '../../../assets/images/general_icons/ai-white.png';
import cyberSecurity from '../../../assets/images/general_icons/cyber-security-white.png';
import microchip from '../../../assets/images/general_icons/microchip-white.png';
import { Link } from "react-router-dom";

import greekLanguage from '../../../locales/gr.json';
import englishLanguage from '../../../locales/en.json';
import { useEffect, useState } from "react";
import fetchSiteText from "../../../utils/GetSiteText/GetSiteText";
import HTMLRenderer from "../../../utils/HTMLRenderer";
import SkeletonLoader from "../../../utils/SkeletonLoader/SkeletonLoader";

const HomeIntro: React.FC<defaultLanguage> = ({ activeLanguage }) => {


    const [isLoading, setIsLoading] = useState(true);

    const [projectTitleGr, setProjectTitleGr] = useState<string>('');
    const [projectTitleEn, setProjectTitleEn] = useState<string>('');
    const [projectTextGr, setProjectTextGr] = useState<string>('');
    const [projectTextEn, setProjectTextEn] = useState<string>('');

    const [aaTitleGr, setaaTitleGr] = useState<string>('');
    const [aaTitleEn, setaaTitleEn] = useState<string>('');
    const [aaEeTitleGr, setaaEeTitleGr] = useState<string>('');
    const [aaEeTitleEn, setaaEeTitleEn] = useState<string>('');
    const [aaScmTitleGr, setaaScmTitleGr] = useState<string>('');
    const [aaScmTitleEn, setaaScmTitleEn] = useState<string>('');
    const [aaCtTitleGr, setaaCtTitleGr] = useState<string>('');
    const [aaCtTitleEn, setaaCtTitleEn] = useState<string>('');
    const [aaEeTextGr, setaaEeTextGr] = useState<string>('');
    const [aaEeTextEn, setaaEeTextEn] = useState<string>('');
    const [aaScmTextGr, setaaScmTextGr] = useState<string>('');
    const [aaScmTextEn, setaaScmTextEn] = useState<string>('');
    const [aaCtTextGr, setaaCtTextGr] = useState<string>('');
    const [aaCtTextEn, setaaCtTextEn] = useState<string>('');

    const [expertiseTitleEn, setExpertiseTitleEn] = useState<string>('');
    const [expertiseTitleGr, setExpertiseTitleGr] = useState<string>('');
    const [expertiseDescriptionEn, setExpertiseDescriptionEn] = useState<string>('');
    const [expertiseDescriptionGr, setExpertiseDescriptionGr] = useState<string>('');
    const [aiTitleEn, setAiTitleEn] = useState<string>('');
    const [aiTitleGr, setAiTitleGr] = useState<string>('');
    const [hpcTitleEn, setHpcTitleEn] = useState<string>('');
    const [hpcTitleGr, setHpcTitleGr] = useState<string>('');
    const [cybersecurityTitleEn, setCybersecurityTitleEn] = useState<string>('');
    const [cybersecurityTitleGr, setCybersecurityTitleGr] = useState<string>('');

    useEffect(() => {
        const fetchResponses = async () => {
            const projectTitle = await fetchSiteText('home_project_title');
            const projectText = await fetchSiteText('home_project_text');

            const aaTitle = await fetchSiteText('home_aa_title');
            const aaEeTitle = await fetchSiteText('home_aa_ee_title');
            const aaScmTitle = await fetchSiteText('home_aa_scm_title');
            const aaCtTitle = await fetchSiteText('home_aa_ct_title');
            const aaEeText = await fetchSiteText('home_aa_ee_text');
            const aaScmText = await fetchSiteText('home_aa_scm_text');
            const aaCtText = await fetchSiteText('home_aa_ct_text');

            const expertiseTitle = await fetchSiteText('home_exp_title');
            const expertiseDescription = await fetchSiteText('home_exp_text');
            const aiTitle = await fetchSiteText('home_exp_ai');
            const hpcTitle = await fetchSiteText('home_exp_hpc');
            const cybersecurityTitle = await fetchSiteText('home_exp_cs');

            if (projectTitle && projectText) {
                setProjectTitleGr(projectTitle.gr_text);
                setProjectTitleEn(projectTitle.en_text);
                setProjectTextGr(projectText?.gr_text);
                setProjectTextEn(projectText?.en_text);
            }

            if (aaTitle && aaEeTitle && aaScmTitle && aaCtTitle && aaEeText && aaScmText && aaCtText) {
                setaaTitleGr(aaTitle.gr_text);
                setaaTitleEn(aaTitle.en_text);
                setaaEeTitleGr(aaEeTitle.gr_text);
                setaaEeTitleEn(aaEeTitle.en_text);
                setaaScmTitleGr(aaScmTitle.gr_text);
                setaaScmTitleEn(aaScmTitle.en_text);
                setaaCtTitleGr(aaCtTitle.gr_text);
                setaaCtTitleEn(aaCtTitle.en_text);
                setaaEeTextGr(aaEeText.gr_text);
                setaaEeTextEn(aaEeText.en_text);
                setaaScmTextGr(aaScmText.gr_text);
                setaaScmTextEn(aaScmText.en_text);
                setaaCtTextGr(aaCtText.gr_text);
                setaaCtTextEn(aaCtText.en_text);
            }

            if (expertiseTitle && expertiseDescription && aiTitle && hpcTitle && cybersecurityTitle) {
                setExpertiseTitleGr(expertiseTitle.gr_text);
                setExpertiseTitleEn(expertiseTitle.en_text);
                setExpertiseDescriptionGr(expertiseDescription.gr_text);
                setExpertiseDescriptionEn(expertiseDescription.en_text);
                setAiTitleGr(aiTitle.gr_text);
                setAiTitleEn(aiTitle.en_text);
                setHpcTitleGr(hpcTitle.gr_text);
                setHpcTitleEn(hpcTitle.en_text);
                setCybersecurityTitleGr(cybersecurityTitle.gr_text);
                setCybersecurityTitleEn(cybersecurityTitle.en_text);
            }
            setIsLoading(false);
        };
        fetchResponses();
    }, []);



    return (
        <>
            <div className="">
                <div className={`relative max-w-1128 m-auto py-10 md:py-20 text-black flex items-center justify-center flex-col px-4 md:px-0`} >
                    <h3 className="text-2xl md:text-4xl text-center mb-8 md:mb-16 flex gap-2">
                        <span className={activeLanguage === 'en' ? "raisonne-pro-font" : ""}>
                            {isLoading ? (
                                <SkeletonLoader height="42px" className="py-4" width="300px" />
                            ) : (
                                <HTMLRenderer htmlContent={activeLanguage == 'en' ? projectTitleEn : projectTitleGr} className={`py-4 text-2xl md:text-3xl ${activeLanguage === 'en' ? "raisonne-pro-font" : ""}`} />
                            )}
                        </span>
                    </h3>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-8">
                        <div className="h-full flex items-center">
                            <img src={greenBg} alt="" className="h-42 md:h-auto md:col-span-1 mx-auto md:mx-0" />
                        </div>

                        <div className="flex flex-col md:col-span-2 gap-4 justify-center text-start">
                            {isLoading ? (
                                <SkeletonLoader height="150px" width="100%" />
                            ) : (
                                <HTMLRenderer htmlContent={activeLanguage == 'en' ? projectTextEn : projectTextGr} className="py-4" />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="py-10 md:py-20 bg-neutral-100 overflow-hidden relative">
                <div className="max-w-1128 m-auto px-4 md:px-0">
                    <h3 className="text-2xl md:text-4xl text-center mb-8 md:mb-12">
                        {isLoading ? (
                            <SkeletonLoader height="40px" width="300px" />
                        ) : (
                            <HTMLRenderer htmlContent={activeLanguage == 'en' ? aaTitleEn : aaTitleGr} className={`py-4 text-2xl md:text-3xl ${activeLanguage === 'en' ? "raisonne-pro-font" : ""}`} />
                        )}
                    </h3>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-4 mt-8 relative z-10 mb-4">
                        {['Energy & Environment', 'Supply Chain & Mobility', 'Culture & Tourism'].map((category, index) => (
                            <Link
                                key={index}
                                to={`/application-areas?category=${encodeURIComponent(category)}`}
                                className={`${index === 1 ? 'bg-[#db6c5a]' :
                                    index === 0 ? 'bg-[#54a979]' :
                                        'bg-[#5faaff]'
                                    }  bg-cover text-white shadow-md py-6 md:py-8 px-4 md:px-6 w-full flex items-center justify-center rounded-sm flex-col text-center gap-4 md:gap-6 select-none cursor-pointer`}
                            >
                                <h2 className="text-xl md:text-2xl">
                                    {isLoading ? (
                                        <SkeletonLoader height="30px" width="200px" />
                                    ) : (
                                        <HTMLRenderer
                                            htmlContent={activeLanguage == 'en' ?
                                                (index === 0 ? aaEeTitleEn : index === 1 ? aaScmTitleEn : aaCtTitleEn) :
                                                (index === 0 ? aaEeTitleGr : index === 1 ? aaScmTitleGr : aaCtTitleGr)}
                                            className={`py-2 md:py-4 text-xl md:text-3xl ${activeLanguage === 'en' ? "raisonne-pro-font" : ""}`}
                                        />
                                    )}
                                </h2>
                                {isLoading ? (
                                    <SkeletonLoader height="60px" width="100%" />
                                ) : (
                                    <HTMLRenderer
                                        htmlContent={activeLanguage === 'en' ?
                                            (index === 0 ? aaEeTextEn : index === 1 ? aaScmTextEn : aaCtTextEn) :
                                            (index === 0 ? aaEeTextGr : index === 1 ? aaScmTextGr : aaCtTextGr)}
                                        className="text-sm md:text-base"
                                    />
                                )}
                                <div>
                                    <IonIcon
                                        icon="arrow-forward-outline"
                                        className="text-xl md:text-2xl text-neutral-50 bg-black p-2 md:p-3 bg-opacity-15 rounded-full hover:shadow-md duration-100 cursor-pointer"
                                    />
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
            <div className="py-10 md:py-20 bg-white overflow-hidden relative">
                <div className="max-w-1128 m-auto w-full relative z-10 min-h-[40vh] flex flex-col items-center justify-center px-4 md:px-0">
                    <h3 className="text-2xl md:text-4xl text-center mb-8 md:mb-12 relative z-10">
                        {isLoading ? (
                            <SkeletonLoader height="40px" width="300px" />
                        ) : (
                            <HTMLRenderer htmlContent={activeLanguage == 'en' ? expertiseTitleEn : expertiseTitleGr} className={`py-4 text-2xl md:text-4xl ${activeLanguage === 'en' ? "raisonne-pro-font" : ""}`} />
                        )}
                    </h3>
                    <p className="w-full md:w-3/4 text-center mx-auto relative z-10 text-sm md:text-base">
                        {isLoading ? (
                            <SkeletonLoader height="80px" width="100%" />
                        ) : (
                            <HTMLRenderer htmlContent={activeLanguage == 'en' ? expertiseDescriptionEn : expertiseDescriptionGr} className="py-4" />
                        )}
                    </p>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-4 mt-8 md:mt-12 relative z-10 w-full shadow-md">
                        {[aiIcon, microchip, cyberSecurity].map((icon, index) => (
                            <div key={index} className="bg-main text-white shadow-md p-4 md:p-6 w-full flex items-center justify-center rounded-sm flex-col text-center gap-4 select-none w-full">
                                <img src={icon} alt="" className="h-10 md:h-12" />
                                <h2 className="text-lg md:text-xl">
                                    {isLoading ? (
                                        <SkeletonLoader height="24px" width="150px" />
                                    ) : (
                                        <HTMLRenderer
                                            htmlContent={activeLanguage == 'en' ?
                                                (index === 0 ? aiTitleEn : index === 1 ? hpcTitleEn : cybersecurityTitleEn) :
                                                (index === 0 ? aiTitleGr : index === 1 ? hpcTitleGr : cybersecurityTitleGr)}
                                            className="py-2 md:py-4 text-lg md:text-xl"
                                        />
                                    )}
                                </h2>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}


export default HomeIntro;