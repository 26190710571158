import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Language } from '../../interfaces';

export const useLanguageInitializer = (setActiveLanguage: (lang: Language) => void): void => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes('/el')) {
      setActiveLanguage('el');
    } else {
      setActiveLanguage('en');
    }
  }, [location, setActiveLanguage]);
};