import React, { useState, useEffect } from "react";

import { partnerType } from "../../../interfaces";
import { ACTIVE_URL } from '../../../constants';
import PartnerImage from "./PartnerImage/PartnerImage";
import HTMLRenderer from "../../../utils/HTMLRenderer";
import fetchSiteText from "../../../utils/GetSiteText/GetSiteText";

interface PartnerComponentProps {
    partnerType: string;
    activeLanguage: string;
}

const PartnerComponent: React.FC<PartnerComponentProps> = ({ partnerType, activeLanguage }) => {
    const [partnerItems, setPartnerItems] = useState<partnerType[]>([]);
    const [loadingItems, setLoadingItems] = useState<boolean>(true);

    const [titleGr, setTitleGr] = useState<string>('');
    const [titleEn, setTitleEn] = useState<string>('');
    const [headerTextGr, setHeaderTextGr] = useState<string>('');
    const [headerTextEn, setHeaderTextEn] = useState<string>('');

    useEffect(() => {
        const fetchResponses = async () => {
            const partnerTitleData = await fetchSiteText(
                partnerType === 'consortium'
                    ? 'cons_part_title'
                    : 'ass_part_title'
            );
            const partnerTextData = await fetchSiteText(
                partnerType === 'consortium'
                    ? 'const_part_text'
                    : 'ass_part_text'
            );

            if (partnerTitleData && partnerTextData) {
                setTitleGr(partnerTitleData.gr_text);
                setTitleEn(partnerTitleData.en_text);
                setHeaderTextGr(partnerTextData?.gr_text);
                setHeaderTextEn(partnerTextData?.en_text);
            }
        };
        fetchResponses();
    }, [partnerType]);

    const fetchResponses = async () => {
        try {
            const response = await fetch(`${ACTIVE_URL}/get_partners/${partnerType}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (response.ok) {
                const data = await response.json();
                console.log(data);
                setPartnerItems([])
                let temp_arr: any = [];
                data.map((item: any) => {
                    if (item.slug in temp_arr) return;
                    setPartnerItems((prev) => [...prev, {
                        id: item.id,
                        name: item.name,
                        image: item.image,
                        type: item.partner_type,
                        partner_link: item.partner_link,
                        org_type: item.org_type
                    }]);
                    temp_arr.push(item.slug);
                })
                setLoadingItems(false);
            } else {
                console.error('Failed to fetch responses');
            }
        } catch (error) {
            console.error('Error fetching responses:', error);
        }
    }

    const setUpLink = (link: string) => {
        if (link.includes('http')) return link;
        return 'http://' + link;
    }

    useEffect(() => {
        setLoadingItems(true);
        fetchResponses();
    }, [partnerType]);


    return (
        <div className="max-w-1128 m-auto pb-8 md:pb-16 min-h-[80vh] px-4 md:px-0">
            <div className="mt-8 md:mt-12">
                <h1 className="text-main text-2xl md:text-3xl font-semibold">
                    <HTMLRenderer htmlContent={activeLanguage == 'en' ? titleEn : titleGr} className="py-2 md:py-4 text-main text-2xl md:text-3xl font-semibold" />
                </h1>
                <HTMLRenderer htmlContent={activeLanguage == 'en' ? headerTextEn : headerTextGr} className="py-2 md:py-4 text-sm md:text-base text-neutral-700" />

                <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-3 md:gap-4">
                    {
                        partnerItems.map((item, index) => {
                            if (item.type === 'consortium' || item.type === 'associate') {
                                return (
                                    <a href={setUpLink(item.partner_link)} target="_blank" rel="noopener noreferrer" key={index} className="w-full mt-4 flex flex-col gap-0.5 select-none cursor-pointer">
                                        <div className="border border-main rounded-sm p-2 flex h-32 md:h-44 items-center justify-center">
                                            <PartnerImage image={item.image} />
                                        </div>
                                        <div className="text-xs md:text-sm text-main">{item.org_type}</div>
                                        <div className="text-sm md:text-base">{item.name}</div>
                                    </a>
                                )
                            }
                            return null;
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default PartnerComponent;