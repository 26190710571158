import React, { useState, useEffect } from "react";
import { IonIcon } from "@ionic/react";
import { partnerType } from "../../../../interfaces";
import { ACTIVE_MEDIA_URL } from '../../../../constants';
import { Button } from "../../../../@/components/ui/button";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "../../../../@/components/ui/select";
import { Input } from "../../../../@/components/ui/input";
import { useAuthContext } from '../../../../utils/useAuthContext';
import { ACTIVE_URL } from '../../../../constants';
import AdminDelete from "../../AdminDelete/AdminDelete";

const AdminPartnerRow: React.FC<partnerType> = ({ id, name, image, type, partner_link, org_type, onEdit }) => {
    const { authTokens } = useAuthContext();
    const [partType, setPartType] = useState<string>(type);
    const [partName, setPartName] = useState<string>(name);
    const [partLink, setPartLink] = useState<string>(partner_link);
    const [partnerImage, setPartnerImage] = useState<string | File>(image);
    const [orgType, setOrgType] = useState<string>(org_type);
    const [imageSrc, setImageSrc] = useState<string>('');
    const [view, setView] = useState<string>('overview');
    const [imgFile, setImageFile] = useState<File | null>(null);
    const [showDelete, setShowDelete] = React.useState<boolean>(false);
    const [isDeleted, setIsDeleted] = React.useState<boolean>(false);

    const closeDelete = () => {
        setShowDelete(false);
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            const selected = files[0];
            if (selected.type.startsWith('image/')) {
                setImageFile(selected);
            } else {
                console.log('Invalid file type. Please select an image.');
            }
        }
    };

    const toggleMode = () => {
        if (view === 'overview') {
            setView('edit');
        } else {
            setView('overview');
        }
    }

    const editPartner = async () => {
        if (!authTokens || !authTokens.access) {
            return;
        }
        try {
            const formData = new FormData();
            formData.append('id', id.toString());
            formData.append('name', partName);
            // formData.append('image', imgFile as Blob);
            formData.append('partner_link', partLink);
            formData.append('partner_type', partType);
            formData.append('org_type', orgType);

            if (imgFile instanceof File && typeof (imgFile) !== 'string') {
                formData.append('image', imgFile);
            } else if (typeof (imgFile) === 'string') {
                formData.append('image', 'none');
            } else {
                formData.append('image', 'none');
            }

            const response = await fetch(`${ACTIVE_URL}/edit_partner/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${authTokens.access}`,
                },
                body: formData,
            });

            if (response.ok) {
                const data = await response.json();
                onEdit && onEdit();
                toggleMode();
            } else {
                console.error('Failed to fetch responses');
            }
        } catch (error) {
            console.error('Error fetching responses:', error);
        }
    }

    useEffect(() => {
        // Check if the image is a File object
        if (image instanceof File) {
            const fileSrc = URL.createObjectURL(image);
            setImageSrc(fileSrc);
            // Cleanup: revoke the object URL when it's no longer needed
            return () => {
                URL.revokeObjectURL(fileSrc);
            };
        } else {
            // Assume image is a string URL
            setImageSrc(image);
        }
    }, [image]);

    const toggleView = () => {
        if (view === 'overview') {
            setView('edit');
        } else {
            setView('overview');
        }
    }

    if (isDeleted) {
        return null;
    }

    return (
        <div>
            {
                view === 'edit' ?
                    <div className="rounded-md border-main border ml-2  mb-2 py-2">
                        <div className="flex items-center justify-between px-4">
                            <div className="text-xl">
                                Edit Partner
                            </div>
                            <div>
                                <Button variant="outline" className="px-3 h-9 text-white bg-neutral-700 hover:bg-neutral-800 hover:text-white" onClick={toggleView}>
                                    <IonIcon icon="remove" className="mr-1 text-lg" />
                                    Dismiss
                                </Button>
                            </div>

                        </div>
                        <div className='px-4 flex flex-col gap-4 mb-2'>
                            <div className='flex flex-col gap-2'>
                                <label className='text-lg text-neutral-800' htmlFor="parent_name">Partner Name</label>
                                <input className=' outline-none border-b border-b-neutral-300' type="text" id="parent_name" placeholder="e.g. NCSR Demokritos" value={partName} onChange={(e) => setPartName(e.target.value)} />
                            </div>
                            <div className='flex flex-col gap-2'>
                                <label className='text-lg text-neutral-800' htmlFor="org_type">Org Type</label>
                                <input className=' outline-none border-b border-b-neutral-300' type="text" id="org_type" placeholder="e.g. SME" value={orgType} onChange={(e) => setOrgType(e.target.value)} />
                            </div>
                            <div className='flex flex-col gap-2'>
                                <label className='text-lg text-neutral-800' htmlFor="picture">Display Image</label>
                                <Input id="picture" type="file" onChange={handleFileChange} accept="image/jpeg, image/jpg, image/png, image/gif" />
                            </div>
                            <div className='flex flex-col gap-2'>
                                <label className='text-lg text-neutral-800' htmlFor="partner_link">Partner Link</label>
                                <input className=' outline-none border-b border-b-neutral-300' type="text" id="partner_link" placeholder="e.g. www.demokritos.gr" value={partLink} onChange={(e) => setPartLink(e.target.value)} />
                            </div>
                            <div className='flex flex-col gap-2'>
                                <label className='text-lg text-neutral-800' htmlFor="partner_link">Partner Type</label>
                                <Select onValueChange={(option: string) => setPartType(option)} value={partType}>
                                    <SelectTrigger className="w-[180px] outline-none h-8">
                                        <SelectValue placeholder="Select User Group" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectGroup>
                                            <SelectItem key={`consortium`} value={'consortium'} >
                                                Consortium Partner
                                            </SelectItem>
                                            <SelectItem key={`associate`} value={'associate'} >
                                                Associate Partner
                                            </SelectItem>
                                        </SelectGroup>
                                    </SelectContent>
                                </Select>
                            </div>
                            <div>
                                <Button variant="outline" className="px-3 h-9 text-white bg-main hover:bg-main hover:text-white" onClick={editPartner}>
                                    <IonIcon icon="pencil" className="mr-1 text-md" />
                                    Edit Partner
                                </Button>
                            </div>
                        </div>
                    </div>
                    :
                    <>
                        <div className="flex items-center select-none justify-between border-main border ml-2 p-1 rounded-md hover:shadow-md hover:bg-neutral-50">
                            <div className="flex items-center gap-3 w-full">
                                <div className="w-10 h-10">
                                    <img src={ACTIVE_MEDIA_URL + imageSrc} alt="" className="w-full h-full object-cover rounded-full" />
                                </div>
                                <span>{name}</span>
                            </div>
                            <div className="flex items-center">
                                <IonIcon icon="trash" className="text-lg text-red-600 cursor-pointer rounded-md hover:bg-neutral-200 p-2" onClick={()=>setShowDelete(true)} />
                                <IonIcon icon="pencil" className="text-lg text-main cursor-pointer rounded-md hover:bg-neutral-200 p-2" onClick={toggleView} />
                            </div>
                        </div>
                        <AdminDelete
                            show={showDelete}
                            onClose={closeDelete}
                            titleText={'Delete Service'}
                            deleteQuestion={'Are you sure you would like to delete this partner?'}
                            deleteText={name}
                            deleteAPIUrl={`${ACTIVE_URL}/delete_partner/${id}`}
                            onDelete={() => setIsDeleted(true)}
                        />
                    </>
            }
        </div>
    );
};

export default AdminPartnerRow;