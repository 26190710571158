import React from 'react';
import { serviceItemType } from '../../../../interfaces';
import { IonIcon } from '@ionic/react';
import { Button } from "../../../../@/components/ui/button";
import { useAuthContext } from '../../../../utils/useAuthContext';
import { ACTIVE_URL } from '../../../../constants'
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
    Root,
} from "../../../../@/components/ui/select";
import AdminDelete from '../../AdminDelete/AdminDelete';

const AdminServiceRow: React.FC<serviceItemType> = ({ id, partner, wp_text, description, short_description, price, ben }) => {
    const { authTokens } = useAuthContext();
    const [viewMode, setViewMode] = React.useState<string>('overview');
    const [partName, setPartName] = React.useState<string>(partner);
    const [desc, setDesc] = React.useState<string>(description);
    const [wp, setWp] = React.useState<string>(wp_text);
    const [showDelete, setShowDelete] = React.useState<boolean>(false);
    const [isDeleted, setIsDeleted] = React.useState<boolean>(false);

    const closeDelete = () => {
        setShowDelete(false);
    }

    const toggleMode = () => {
        if (viewMode === 'overview') {
            setViewMode('edit');
        } else {
            setViewMode('overview');
        }
    }

    const editService = async () => {
        if (!authTokens || !authTokens.access) {
            return;
        }
        try {
            const formData = new FormData();
            formData.append('partner', partName);
            formData.append('description', desc);
            formData.append('id', id.toString());
            formData.append('wp', wp);

            const response = await fetch(`${ACTIVE_URL}/edit_service/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${authTokens.access}`
                },
                body: formData
            });

            if (response.ok) {
                console.log('Service edited successfully');
                setViewMode('overview');
            } else {
                console.log('Failed to edit service');
            }
        } catch (error) {
            console.log('Failed to edit service');
        }
    }

    if (isDeleted) {
        return null;
    }

    return (
        viewMode === 'overview' ? (
            <>
                <div className="flex items-center select-none justify-between border-main border p-1.5 rounded-md hover:shadow-md hover:bg-neutral-50">
                    <div className='flex items-center gap-3 w-full'>
                        <h3 className='text-sm w-32 text-neutral-600'>{partner}</h3>
                        <p className=''>{short_description}</p>
                    </div>
                    <div className='flex items-center'>
                        <IonIcon icon='trash' className='text-lg text-red-600 cursor-pointer rounded-md hover:bg-neutral-200 p-2' onClick={()=>setShowDelete(true)} />
                        <IonIcon icon='pencil' className='text-lg text-main cursor-pointer rounded-md hover:bg-neutral-200 p-2' onClick={toggleMode} />
                    </div>
                </div>
                <AdminDelete 
                    show={showDelete} 
                    onClose={closeDelete} 
                    titleText={'Delete Service'} 
                    deleteQuestion={'Are you sure you would like to delete this service?'}
                    deleteText={partner + ' - ' + short_description}
                    deleteAPIUrl={`${ACTIVE_URL}/delete_service/${id}`}
                    onDelete={() => setIsDeleted(true)}
                />
            </>
        ) : (
            <div className="rounded-md border-main border  mb-2 py-2">
                <div className="flex items-center justify-between px-4">
                    <div className="text-xl">
                        Edit Service
                    </div>
                    <div>
                        <Button variant="outline" className="px-3 h-9 text-white bg-neutral-700 hover:bg-neutral-800 hover:text-white" onClick={toggleMode}>
                            <IonIcon icon="remove" className="mr-1 text-lg" />
                            Dismiss
                        </Button>
                    </div>
                </div>
                <div className='px-4 flex flex-col gap-4 mb-2'>
                    <div className='flex flex-col gap-2'>
                        <label className='text-lg text-neutral-800' htmlFor="parent_name">Partner Name</label>
                        <input className=' outline-none border-b border-b-neutral-300' type="text" id="parent_name" placeholder="e.g. NCSR Demokritos" value={partName} onChange={(e) => setPartName(e.target.value)} />
                    </div>
                    <div className='flex flex-col gap-2'>
                        <label className='text-lg text-neutral-800' htmlFor="short_desc">Description</label>
                        <input className=' outline-none border-b border-b-neutral-300' type="text" id="short_desc" placeholder="Description" value={desc} onChange={(e) => setDesc(e.target.value)} />
                    </div>
                    <div className='flex flex-col gap-2 select-none'>
                        <label className='text-lg text-neutral-800' htmlFor="wp_select">Wp Category</label>
                        <Select onValueChange={(option) => setWp(option)} value={wp}>
                            <SelectTrigger className="w-[350px] outline-none h-8">
                                <SelectValue placeholder="Select Service WP" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectGroup>
                                    <SelectItem key={`Test Before Invest`} value={'Test Before Invest'} >
                                        Test Before Invest
                                    </SelectItem>
                                    <SelectItem key={`Innovation ecosystem and networking`} value={'Innovation ecosystem and networking'} >
                                        Innovation ecosystem and networking
                                    </SelectItem>
                                    <SelectItem key={`Skills and training`} value={'Skills and training'} >
                                        Skills and training
                                    </SelectItem>
                                    <SelectItem key={`Support to find investment`} value={'Support to find investment'} >
                                        Support to find investment
                                    </SelectItem>
                                </SelectGroup>
                            </SelectContent>
                        </Select>
                    </div>
                    <div>
                        <Button variant="outline" className="px-3 h-9 text-white bg-main hover:bg-main hover:text-white" onClick={editService}>
                            <IonIcon icon="pencil" className="mr-1 text-md" />
                            Edit Service
                        </Button>
                    </div>
                </div>
            </div>
        )
    );
}

export default AdminServiceRow;
