import React, { useEffect, useState } from 'react';
import './App.css';

import { BrowserRouter, Routes, Route, useLocation, Navigate, useNavigate } from 'react-router-dom';
import { AuthProvider } from './utils/AuthContext';

import LoginPage from './Pages/LoginPage/LoginPage';
import HomePage from './Pages/HomePage/HomePage'
import AssociatePartnersPage from './Pages/AssociatePartnersPage/AssociatePartnersPage';
import ConsortiumPartnersPage from './Pages/ConsortiumPartnersPage/ConsortiumPartnersPage';

import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import PrivateRoute from './utils/PrivateRoute';
import Admin from './Pages/AdminPage/Admin';
import NewsPage from './Pages/NewsPage/NewsPage';
import NewsHome from './Pages/NewsHome/NewsHome';
import ServicesPage from './Pages/ServicesPage/ServicesPage';
import { LanguageChangerProps, LanguageInitializerProps } from './interfaces';
import LanguageLayout from './utils/LanguageLayout/LanguageLayout';
import NotFound from './Pages/NotFound/NotFound';
import ApplicationAreasPage from './Pages/ApplicationAreasPage/ApplicationAreasPage'
import EDIHNetworkPage from './Pages/EDIHNetworkPage/EDIHNetworkPage';

const LanguageInitializer: React.FC<LanguageInitializerProps> = ({ onLanguageDetected }) => {
  const location = useLocation();

  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    const langSlug = pathSegments[1];
    if (['en', 'el'].includes(langSlug)) {
      onLanguageDetected(langSlug);
    }
  }, [location, onLanguageDetected]);

  return null;
};

const getPath = (path: string, activeLanguage: string) => {
  return activeLanguage === 'en' ? path : `/:lang${path}`;
};


const AppRoutes: React.FC<LanguageChangerProps> = ({ activeLanguage, setActiveLanguage }) => (


  <Routes>
    <Route path={getPath('/', activeLanguage)} element={<LanguageLayout setActiveLanguage={setActiveLanguage}><HomePage activeLanguage={activeLanguage} /></LanguageLayout>} />
    <Route path={getPath('/news', activeLanguage)} element={<LanguageLayout setActiveLanguage={setActiveLanguage}><NewsHome activeLanguage={activeLanguage} /></LanguageLayout>} />
    <Route path={getPath('/news/:slug', activeLanguage)} element={<LanguageLayout setActiveLanguage={setActiveLanguage}><NewsPage activeLanguage={activeLanguage} /></LanguageLayout>} />
    <Route path={getPath('/associate-partners', activeLanguage)} element={<LanguageLayout setActiveLanguage={setActiveLanguage}><AssociatePartnersPage activeLanguage={activeLanguage} /></LanguageLayout>} />
    <Route path={getPath('/consortium-partners', activeLanguage)} element={<LanguageLayout setActiveLanguage={setActiveLanguage}><ConsortiumPartnersPage activeLanguage={activeLanguage} /></LanguageLayout>} />
    <Route path={getPath('/services', activeLanguage)} element={<LanguageLayout setActiveLanguage={setActiveLanguage}><ServicesPage activeLanguage={activeLanguage} /></LanguageLayout>} />
    <Route path={getPath('/application-areas', activeLanguage)} element={<LanguageLayout setActiveLanguage={setActiveLanguage}><ApplicationAreasPage activeLanguage={activeLanguage} /></LanguageLayout>} />
    <Route path={getPath('/edih-network', activeLanguage)} element={<LanguageLayout setActiveLanguage={setActiveLanguage}><EDIHNetworkPage activeLanguage={activeLanguage} /></LanguageLayout>} />
    <Route path={getPath('/admin', activeLanguage)}
      element={
        <PrivateRoute authenticationPath={getPath('/login', activeLanguage)} >
          <Admin />
        </PrivateRoute>
      }
    />
    <Route path="*" element={<LanguageLayout setActiveLanguage={setActiveLanguage}><NotFound activeLanguage={activeLanguage} /></LanguageLayout>} />
    <Route path={getPath('/login', activeLanguage)} element={<LoginPage />} />
  </Routes>
);


function App() {
  const [activeLanguage, setActiveLanguage] = React.useState('en');
  const [currentRoute, setCurrentRoute] = useState<string>(window.location.pathname);

  const handleLanguageChange = (language: string) => {
    setActiveLanguage(language);
  };




  return (
    <BrowserRouter>
      <Navbar activeLanguage={activeLanguage} setActiveLanguage={handleLanguageChange}/>

      <AuthProvider>
        <AppRoutes activeLanguage={activeLanguage} setActiveLanguage={handleLanguageChange} />
      </AuthProvider>
      <Footer activeLanguage={activeLanguage} />
    </BrowserRouter>
  );
}

export default App;
