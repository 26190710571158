import React, { useState, useEffect, useRef } from "react";
import { serviceItemType } from "../../../interfaces";
import { IonIcon } from "@ionic/react";


const ServiceItem: React.FC<serviceItemType> = ({ id, description, short_description, partner, ben, wp_text }) => {



    return (
        <div className="w-full border-b py-4 border-b-neutral-300 hover:border-b-main duration-100 cursor-pointer border-b-2 flex flex-col gap-2 hover:text-main">
            <div className="text-sm w-full flex justify-between"><span className="text-main">{partner}</span><span className="text-neutral-500">{wp_text}</span></div>
            <div className="flex justify-between gap-10 items-center">
                <div className="">
                    {description}
                </div>
                <div className="h-full justify-center items-center">
                    {/* <IonIcon icon="chevron-forward" /> */}
                </div>
            </div>

        </div>
    )
}

export default ServiceItem;