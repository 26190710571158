import React, { useState, useEffect, useRef } from "react";
import { defaultLanguage } from "../../interfaces";
import { serviceItemType } from "../../interfaces";
import { ACTIVE_URL } from "../../constants";
import ServiceItem from "./ServiceItem/ServiceItem";
import { IonIcon } from "@ionic/react";
import HTMLRenderer from "../../utils/HTMLRenderer";
import greekLanguage from '../../locales/gr.json';
import englishLanguage from '../../locales/en.json';
import { useSearchParams } from 'react-router-dom';
import fetchSiteText from "../../utils/GetSiteText/GetSiteText";


const Services: React.FC<defaultLanguage> = ({ activeLanguage }) => {
    const [searchParams] = useSearchParams();
    const activeServiceParam = searchParams.get('service');

    const languageData = activeLanguage === 'el' ? greekLanguage : englishLanguage;


    const [titleGr, setTitleGr] = useState<string>('');
    const [titleEn, setTitleEn] = useState<string>('');
    const [headerTextGr, setHeaderTextGr] = useState<string>('');
    const [headerTextEn, setHeaderTextEn] = useState<string>('');

    useEffect(() => {
        const fetchResponses = async () => {
            const servicesTitleData = await fetchSiteText('services_page_title');
            const servicesTextData = await fetchSiteText('services_page_text');
            if (servicesTitleData && servicesTextData) {
                setTitleGr(servicesTitleData.gr_text);
                setTitleEn(servicesTitleData.en_text);
                setHeaderTextGr(servicesTextData?.gr_text);
                setHeaderTextEn(servicesTextData?.en_text);
            }
        };
        fetchResponses();
    }, []);

    const [loadingItems, setLoadingItems] = useState<boolean>(true);
    const [services, setServices] = useState<serviceItemType[]>([]);
    const [activeServices, setActiveServices] = useState<serviceItemType[]>([]);

    const [wpCategories, setWpCategories] = useState<string[]>([]);
    const [activeWpCategories, setActiveWpCategories] = useState<string[]>([]);

    const [partnerCategories, setPartnerCategories] = useState<string[]>([]);
    const [activePartnerCategories, setActivePartnerCategories] = useState<string[]>([]);

    const [areCategoriesOpen, setAreCategoriesOpen] = useState<boolean>(true);
    const [arePartnerCategoriesOpen, setArePartnerCategoriesOpen] = useState<boolean>(true);


    const [itemsPerPage, setItemsPerPage] = useState<number>(7);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);

    const initialSetup = useRef<boolean>(false);

    const hanldeReduceItemsPerPage = () => {
        if (itemsPerPage > 1) {
            setItemsPerPage((prev) => prev - 1);
        }
    }

    const hanldeIncreaseItemsPerPage = () => {
        if (itemsPerPage < 30) {
            setItemsPerPage((prev) => prev + 1);
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const fetchResponses = async () => {
            try {
                const response = await fetch(`${ACTIVE_URL}/get_services/`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    setServices([]);
                    let temp_arr: any = []; // Consider using a Set for efficiency if slugs are unique.
                    let filteredData = data.filter((item: any) => {
                        if (temp_arr.includes(item.id)) return false;
                        temp_arr.push(item.id);
                        return true;
                    }).reverse();



                    // Set services with filteredData directly
                    setServices(filteredData.map((item: any) => ({
                        id: item.id,
                        description: item.service_description,
                        short_description: item.short_description,
                        partner: item.partner,
                        wp_text: item.wp_text,
                        ben: item.ben,
                    })));

                    setLoadingItems(false);

                    // Calculate and set total pages
                    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
                    setTotalPages(totalPages);
                } else {
                    console.error('Failed to fetch responses');
                }
            } catch (error) {
                console.error('Error fetching responses:', error);
            }
        };
        fetchResponses();
    }, []);



    const extractAvailableWP = (services: serviceItemType[]) => {
        return services.reduce((acc: string[], service) => {
            if (!acc.includes(service.wp_text)) {
                acc.push(service.wp_text);
            }
            return acc;
        }, []);
    }

    const extractAvailablePartners = (services: serviceItemType[]) => {
        return services.reduce((acc: string[], service) => {
            if (!acc.includes(service.partner)) {
                acc.push(service.partner);
            }
            return acc;
        }, []);
    }
    useEffect(() => {
        if (!initialSetup.current && services.length > 0) {
            setWpCategories(extractAvailableWP(services));
            setPartnerCategories(extractAvailablePartners(services));
            if (activeServiceParam) {
                const activeServiceName = decodeURIComponent(activeServiceParam);

                setActiveWpCategories([activeServiceName]);
            } else {
                setActiveWpCategories(['Test Before Invest']);
            }
            // setActiveWpCategories(['Test Before Invest']);
            setActivePartnerCategories(extractAvailablePartners(services));
            setActiveServices(services);
            initialSetup.current = true;
        }
    }, [services]);

    const handleCategoryClick = (category: string) => {
        if (activeWpCategories.includes(category) && activeWpCategories.length > 1) {
            // setActiveWpCategories((prev) => prev.filter((item) => item !== category));
            setActiveWpCategories([category]);
        } else if (!activeWpCategories.includes(category)) {
            // setActiveWpCategories((prev) => [...prev, category]);
            setActiveWpCategories([category]);
        }
    }

    const handlePartnerCategoryClick = (category: string) => {
        if (activePartnerCategories.includes(category) && activePartnerCategories.length > 1) {
            setActivePartnerCategories((prev) => prev.filter((item) => item == category));
            // setActivePartnerCategories([category]);
        } else if (!activePartnerCategories.includes(category)) {
            // setActivePartnerCategories((prev) => [...prev, category]);
            setActivePartnerCategories([category]);
        }
    }

    useEffect(() => {
        if (activeWpCategories.length > 0 || activePartnerCategories.length > 0) {
            setActiveServices(services.filter((service) =>
                activeWpCategories.includes(service.wp_text) &&
                activePartnerCategories.includes(service.partner)
            ));
        } else {
            setActiveServices(services);
        }
    }, [activeWpCategories, activePartnerCategories, services]);


    useEffect(() => {
        if (services.length === 0) {
            setCurrentPage(0);
        } else {
            setCurrentPage(1);
        }

    }, [activeServices])


    // Pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = activeServices.slice(indexOfFirstItem, indexOfLastItem);
    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

    useEffect(() => {
        setTotalPages(Math.ceil(activeServices.length / itemsPerPage));
    }, [itemsPerPage, activeServices.length]);

    const goToNextPage = () => {
        if (currentPage === totalPages || currentPage > totalPages) return;
        setCurrentPage((prev) => prev + 1);
    };

    const goToPreviousPage = () => {
        if (currentPage === totalPages || currentPage > totalPages) return;
        setCurrentPage((prev) => prev - 1);
    };

    return (
        <div>
            <div className="max-w-1128 m-auto pb-8 md:pb-16 min-h-screen px-4 md:px-0">
                <div className="w-full justify-between items-start flex-col flex mb-4 md:mb-8 mt-6 md:mt-12">
                    <h1 className="text-main text-2xl md:text-3xl font-semibold">
                        <HTMLRenderer htmlContent={activeLanguage == 'en' ? titleEn : titleGr} className="py-2 md:py-4 text-base md:text-xl text-main" />
                    </h1>
                    <HTMLRenderer htmlContent={activeLanguage == 'en' ? headerTextEn : headerTextGr} className="py-2 md:py-4 text-sm md:text-base text-neutral-700" />
                </div>
                <div className="flex flex-col md:flex-row gap-4 md:gap-8 w-full">
                    <div className="w-full md:w-1/4 h-auto">
                        <div className="w-full bg-neutral-200 py-4 px-2 rounded-md shadow-md flex flex-col gap-2 cursor-pointer select-none">
                            <div className="w-full px-1 flex justify-between items-center text-main">
                                <div className="text-lg font-semibold">Categories</div>
                                <div className="text-sm bg-main text-white px-2 py-0.5 rounded-md" onClick={() => setActiveWpCategories(wpCategories)}>
                                    Select All
                                </div>
                            </div>
                            <div className={`${!areCategoriesOpen ? 'max-h-0' : 'h-auto'} duration-200 flex flex-col gap-2`}>
                                {wpCategories.map((category, index) => (
                                    <div
                                        key={index}
                                        className={`${activeWpCategories.includes(category) ? 'bg-main' : 'bg-neutral-500'} ${!areCategoriesOpen ? 'hidden' : ''} py-2 text-white text-sm md:text-base px-2 flex items-center justify-center text-center rounded-md cursor-pointer gap-0.5 hover:shadow-md`}
                                        onClick={() => handleCategoryClick(category)}
                                    >
                                        {category}
                                    </div>
                                ))}
                            </div>
                            <div className="">
                                <div className="w-full flex justify-between items-center text-main px-1 py-2">
                                    <div className="text-lg font-semibold">Partner</div>
                                    <div className="text-sm bg-main text-white px-2 py-0.5 rounded-md" onClick={() => setActivePartnerCategories(partnerCategories)}>
                                        Select All
                                    </div>
                                </div>
                                <div className={`${!arePartnerCategoriesOpen ? 'max-h-0' : 'h-auto'} duration-200 flex flex-col gap-2 max-h-[260px] overflow-scroll`}>
                                    {partnerCategories.map((category, index) => (
                                        <div
                                            key={index}
                                            className={`${activePartnerCategories.includes(category) ? 'bg-main' : 'bg-neutral-500'} ${!arePartnerCategoriesOpen ? 'hidden' : ''} py-2 text-white text-sm md:text-base px-2 flex items-center justify-center text-center rounded-md cursor-pointer gap-0.5 hover:shadow-md`}
                                            onClick={() => handlePartnerCategoryClick(category)}
                                        >
                                            {category}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-3/4">
                        <div className="w-full hidden md:flex justify-between items-center select-none">
                            <div className="flex items-center gap-2 text-sm">
                                <button className="py-1 px-2 bg-neutral-200 rounded-md cursor-pointer flex gap-0.5 items-center text-neutral-700 hover:text-neutral-900 hover:shadow-md" onClick={goToPreviousPage} disabled={currentPage === 1}>
                                    <IonIcon icon="chevron-back" />
                                    <div>Previous</div>
                                </button>
                                <span>{currentPage} out of {totalPages}</span>
                                <button className="py-1 px-2 bg-neutral-200 rounded-md cursor-pointer flex gap-0.5 items-center text-neutral-700 hover:text-neutral-900 hover:shadow-md" onClick={goToNextPage} disabled={currentPage === totalPages}>
                                    <div>Next</div>
                                    <IonIcon icon="chevron-forward" />
                                </button>
                            </div>
                            <div className="flex items-center justify-center gap-2 text-sm">
                                <div>Items per page:</div>
                                <div
                                    className="py-1 w-8 flex items-center justify-center bg-neutral-200 rounded-md cursor-pointer flex gap-0.5 items-center text-neutral-700 hover:text-neutral-900 hover:shadow-md"
                                    onClick={hanldeReduceItemsPerPage}
                                >
                                    -
                                </div>
                                <div>
                                    {itemsPerPage}
                                </div>
                                <div
                                    className="py-1 w-8 flex items-center justify-center bg-neutral-200 rounded-md cursor-pointer flex gap-0.5 items-center text-neutral-700 hover:text-neutral-900 hover:shadow-md"
                                    onClick={hanldeIncreaseItemsPerPage}
                                >
                                    +
                                </div>
                            </div>
                        </div>
                        <div className="py-4">
                            {activeServices.length === 0 && !loadingItems && (
                                <div className="text-center pt-2 text-neutral-700">No services found</div>
                            )}
                            {loadingItems ? (
                                <div className="text-center">Loading...</div>
                            ) : (
                                currentItems.map((item, index) => (
                                    <ServiceItem key={index} {...item} />
                                ))
                            )}
                        </div>
                        <div className="w-full flex md:hidden flex-col gap-8 justify-between items-center select-none mt-8 mb-4">
                            <div className="flex items-center gap-2 text-sm">
                                <button className="py-1 px-2 bg-neutral-200 rounded-md cursor-pointer flex gap-0.5 items-center text-neutral-700 hover:text-neutral-900 hover:shadow-md" onClick={goToPreviousPage} disabled={currentPage === 1}>
                                    <IonIcon icon="chevron-back" />
                                    <div>Previous</div>
                                </button>
                                <span>{currentPage} out of {totalPages}</span>
                                <button className="py-1 px-2 bg-neutral-200 rounded-md cursor-pointer flex gap-0.5 items-center text-neutral-700 hover:text-neutral-900 hover:shadow-md" onClick={goToNextPage} disabled={currentPage === totalPages}>
                                    <div>Next</div>
                                    <IonIcon icon="chevron-forward" />
                                </button>
                            </div>
                            <div className="flex items-center justify-center gap-2 text-sm">
                                <div>Items per page:</div>
                                <div
                                    className="py-1 w-8 flex items-center justify-center bg-neutral-200 rounded-md cursor-pointer flex gap-0.5 items-center text-neutral-700 hover:text-neutral-900 hover:shadow-md"
                                    onClick={hanldeReduceItemsPerPage}
                                >
                                    -
                                </div>
                                <div>
                                    {itemsPerPage}
                                </div>
                                <div
                                    className="py-1 w-8 flex items-center justify-center bg-neutral-200 rounded-md cursor-pointer flex gap-0.5 items-center text-neutral-700 hover:text-neutral-900 hover:shadow-md"
                                    onClick={hanldeIncreaseItemsPerPage}
                                >
                                    +
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services;