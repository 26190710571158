import React, { useEffect, useRef, useState } from 'react';
import { LanguageChangerProps } from '../../interfaces';
import logo from '../../assets/images/logos/sa_logo_white.png';
import blue_logo from '../../assets/images/logos/sa_logo_blue.png';
import { useLocation, useNavigate } from 'react-router-dom';
import greekLanguage from '../../locales/gr.json';
import englishLanguage from '../../locales/en.json';
import { Link } from 'react-router-dom';
import { IonIcon } from "@ionic/react";
import {
    Drawer,
    DrawerClose,
    DrawerContent,
    DrawerDescription,
    DrawerFooter,
    DrawerHeader,
    DrawerTitle,
    DrawerTrigger,
} from "../../@/components/ui/drawer"
import { Button } from "../../@/components/ui/button";
import { Sling as Hamburger } from 'hamburger-react';

const Navbar: React.FC<LanguageChangerProps> = ({ activeLanguage, setActiveLanguage }) => {
    const languageData = activeLanguage === 'el' ? greekLanguage : englishLanguage;
    const [isConsOpen, setIsConsOpen] = useState(false);
    const [isColabOpen, setIsColabOpen] = useState(false);
    const navigate = useNavigate();

    const consDropDownRef = useRef(null);
    const colabDropDownRef = useRef(null);

    const [inWhiteBackground, setInWhiteBackground] = useState(false);
    const location = useLocation();

    const [isFixed, setIsFixed] = useState(false);
    const [isOnTop, setIsOnTop] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);

    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsDesktop(window.innerWidth > 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            if (currentScrollY === 0) {
                setIsFixed(false);
                setIsOnTop(true);
            } else if (lastScrollY > currentScrollY) {
                setIsFixed(true);
                setIsOnTop(false);
            } else if (lastScrollY < currentScrollY && isFixed) {
                setIsFixed(false);
                setIsOnTop(true);
            }
            setLastScrollY(currentScrollY);
        };

        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollY, isFixed]);

    const pathWithoutLang = activeLanguage === 'el'
        ? location.pathname.replace(`/el`, '')
        : location.pathname;

    const wBMainCondition = !['', '/', '/login', '/press'].includes(pathWithoutLang) &&
        !(pathWithoutLang === '/news' || pathWithoutLang.startsWith('/news/') && pathWithoutLang.split('/').length === 3);

    useEffect(() => {
        if (wBMainCondition && !inWhiteBackground) {
            setInWhiteBackground(true);
        } else if (!wBMainCondition && inWhiteBackground) {
            setInWhiteBackground(false);
        }
    }, [pathWithoutLang, wBMainCondition]);

    if (location.pathname.startsWith('/admin')) {
        return null;
    }

    const handleConsMouseEnter = () => setIsConsOpen(true);
    const handleConsMouseLeave = () => setIsConsOpen(false);

    const handleColabMouseEnter = () => setIsColabOpen(true);
    const handleColabMouseLeave = () => setIsColabOpen(false);

    const setLanguage = (language: string) => {
        setActiveLanguage(language);
        const currentPath = window.location.pathname;
        const pathWithoutLang = currentPath.replace(/^\/(en|el)/, '') || '/';
        const newPath = language === 'el' ? `/el${pathWithoutLang}` : pathWithoutLang;
        navigate(newPath);
    };

    return (
        <div className={`w-full z-50 ${isFixed ? 'fixed top-0 animate-nav-slide-in' : (isOnTop ? 'absolute top-0' : 'fixed top-[-100%] animate-nav-slide-out')} ${!inWhiteBackground ? 'bg-main' : 'bg-white'} `}>
            <div className={`w-full flex justify-between items-center m-auto ${!inWhiteBackground ? 'text-neutral-100' : 'text-neutral-900'} h-24 content-max-w m-auto`}>
                <Link to={`${activeLanguage == 'el' ? '/el' : ''}/`} className="flex items-center pl-4 1240:pl-0">
                    <img src={!inWhiteBackground ? logo : blue_logo} alt="" className='h-12 sm:h-14 md:h-16' />
                </Link>

                {isDesktop ? (
                    <div className={`background flex gap-6 px-4 py-2 ${activeLanguage == 'el' ? 'open-sans-font text-sm' : 'raisonne-pro-font'} ${!inWhiteBackground ? 'text-neutral-100' : 'text-main'}`}>
                        <div ref={consDropDownRef} onMouseEnter={handleConsMouseEnter} onMouseLeave={handleConsMouseLeave}>
                            <Link to={`${activeLanguage == 'el' ? '/el' : ''}/consortium-partners`} className="inline-flex items-center focus:outline-none raisonne-pro-font">
                                {languageData.menu.about}
                            </Link>
                            {isConsOpen && (
                                <div className="z-10 absolute pt-3 divide-y w-44">
                                    <ul className={`text-base white bg-main border-t border-t-2 shadow-lg ${!inWhiteBackground ? 'border-t-white' : 'border-t-main'}`}>
                                        <li>
                                            <Link to={`${activeLanguage == 'el' ? '/el' : ''}/consortium-partners`} className="block px-2 py-2 text-white raisonne-pro-font">{languageData.menu.consortium_partners}</Link>
                                        </li>
                                        <li>
                                            <Link to={`${activeLanguage == 'el' ? '/el' : ''}/edih-network`} className="block px-2 py-2 text-white raisonne-pro-font">{languageData.menu.edih_network}</Link>
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </div>
                        <Link to={`${activeLanguage == 'el' ? '/el' : ''}/services`} className='raisonne-pro-font'>
                            {languageData.menu.services}
                        </Link>
                        <Link to={`${activeLanguage == 'el' ? '/el' : ''}/application-areas`} className='raisonne-pro-font'>
                            {languageData.menu.application_areas}
                        </Link>
                        <div ref={colabDropDownRef} onMouseEnter={handleColabMouseEnter} onMouseLeave={handleColabMouseLeave}>
                            <Link to={`${activeLanguage == 'el' ? '/el' : ''}/associate-partners`} className="inline-flex items-center focus:outline-none raisonne-pro-font">
                                {languageData.menu.collaboration}
                            </Link>
                            {isColabOpen && (
                                <div className="z-10 absolute pt-3 divide-y w-44">
                                    <ul className={`text-base white bg-main border-t border-t-2 shadow-lg ${!inWhiteBackground ? 'border-t-white' : 'border-t-main'}`}>
                                        <li>
                                            <Link to={`${activeLanguage == 'el' ? '/el' : ''}/associate-partners`} className="block px-2 py-2 text-white raisonne-pro-font">{languageData.menu.associate_partners}</Link>
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </div>
                        <Link to={`${activeLanguage == 'el' ? '/el' : ''}/news`} className='raisonne-pro-font'>
                            {languageData.menu.news}
                        </Link>
                        <div>
                            {activeLanguage === 'en' ?
                                <button onClick={() => setLanguage('el')} className="focus:outline-none flex mt-[3px]">
                                    <img src="https://hatscripts.github.io/circle-flags/flags/gb.svg" alt="" className='h-[18px] border border-neutral-300 rounded-full' />
                                </button>
                                :
                                <button onClick={() => setLanguage('en')} className="focus:outline-none flex mt-[3px]">
                                    <img src="https://hatscripts.github.io/circle-flags/flags/gr.svg" alt="" className='h-[18px] border border-neutral-300 rounded-full' />
                                </button>
                            }
                        </div>
                    </div>
                ) : (
                    <Drawer open={isMenuOpen && !isDesktop} onOpenChange={setIsMenuOpen}>
                        <DrawerTrigger>
                            <div className="cursor-pointer ml-[-10px] scale-[60%] rounded-full">
                                <Hamburger easing="ease-in" color={inWhiteBackground ? 'black' : 'white'} label="Show menu" toggled={isMenuOpen} />
                            </div>
                        </DrawerTrigger>
                        <DrawerContent className='bg-white'>
                            <DrawerHeader className="text-left text-center pt-6 pb-4 ">
                                <DrawerTitle className='text-main'>Menu</DrawerTitle>
                                <DrawerDescription></DrawerDescription>
                            </DrawerHeader>
                            <div className="h-full flex flex-col items-center justify-center text-lg gap-4 py-8">
                                <Link to={`${activeLanguage == 'el' ? '/el' : ''}/`} onClick={() => setIsMenuOpen(false)}>{languageData.menu.home}</Link>
                                <Link to={`${activeLanguage == 'el' ? '/el' : ''}/edih-network`} onClick={() => setIsMenuOpen(false)}>{languageData.menu.edih_network}</Link>
                                <Link to={`${activeLanguage == 'el' ? '/el' : ''}/consortium-partners`} onClick={() => setIsMenuOpen(false)}>{languageData.menu.consortium_partners}</Link>
                                <Link to={`${activeLanguage == 'el' ? '/el' : ''}/services`} onClick={() => setIsMenuOpen(false)}>{languageData.menu.services}</Link>
                                <Link to={`${activeLanguage == 'el' ? '/el' : ''}/application-areas`} onClick={() => setIsMenuOpen(false)}>{languageData.menu.application_areas}</Link>
                                <Link to={`${activeLanguage == 'el' ? '/el' : ''}/associate-partners`} onClick={() => setIsMenuOpen(false)}>{languageData.menu.associate_partners}</Link>
                                <Link to={`${activeLanguage == 'el' ? '/el' : ''}/news`} onClick={() => setIsMenuOpen(false)}>{languageData.menu.news}</Link>
                                <div>
                                    {activeLanguage === 'en' ?
                                        <button onClick={() => { setLanguage('el'); setIsMenuOpen(false); }} className="focus:outline-none flex mt-[3px] items-center gap-1 text-base">
                                            <img src="https://hatscripts.github.io/circle-flags/flags/gb.svg" alt="" className='h-[18px] border border-neutral-300 rounded-full' />
                                            EN
                                        </button>
                                        :
                                        <button onClick={() => { setLanguage('en'); setIsMenuOpen(false); }} className="focus:outline-none flex mt-[3px] items-center gap-1 text-base">
                                            <img src="https://hatscripts.github.io/circle-flags/flags/gr.svg" alt="" className='h-[18px] border border-neutral-300 rounded-full' />
                                            ΕΛ
                                        </button>
                                    }
                                </div>
                            </div>
                            <DrawerFooter>
                                <DrawerClose>
                                    <Button variant="outline" className="border w-full py-2 text-center border-neutral-800 rounded-sm items-center justify-center flex gap-1.5 cursor-pointer bg-white text-lg">
                                        <IonIcon icon="caret-forward" className="text-lg mb-0.5" />
                                        Close Menu
                                    </Button>
                                </DrawerClose>
                            </DrawerFooter>
                        </DrawerContent>
                    </Drawer>
                )}
            </div>
        </div>
    );
}

export default Navbar;