import { ACTIVE_URL } from "../../constants";

interface SiteTextData {
    gr_text: string;
    en_text: string;
}

const fetchSiteText = async (textPosition: string): Promise<SiteTextData | null> => {
    try {
        const response = await fetch(`${ACTIVE_URL}/texts/get/${textPosition}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (response.ok) {
            const data: SiteTextData = await response.json();
            return data;
        } else {
            console.error(`Failed to fetch text for "${textPosition}"`);
            return null;
        }
    } catch (error) {
        console.error(`Error fetching text for "${textPosition}":`, error);
        return null;
    }
};

export default fetchSiteText;