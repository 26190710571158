import { IonIcon } from "@ionic/react";
import Modal from "../../../utils/Modal/Modal";
import { useAuthContext } from '../../../utils/useAuthContext';

interface AdminDeleteProps {
    show: boolean;
    onClose: () => void;
    titleText: string;
    deleteQuestion: string;
    deleteText: string;
    deleteAPIUrl: string;
    onDelete: () => void;
}

const AdminDelete: React.FC<AdminDeleteProps> = ({ show, onClose, titleText, deleteQuestion, deleteText, onDelete, deleteAPIUrl }) => {
    const { authTokens } = useAuthContext();
    const delItem = async () => {
        if (!authTokens || !authTokens.access) {
            return;
        }
        const response = await fetch(deleteAPIUrl, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${authTokens.access}`,
            },
        });
        if (response.ok) {
            console.log('Item deleted successfully');
            onDelete();
        } else {
            console.log('Failed to delete item');
        }
    }

    return (

        <Modal onClose={onClose} show={show} titleText={titleText} width="w-[500px]" titleIcon={<IonIcon icon="person" className='text-md ion-icon-default-transform mr-1' />}>
            <div className="px-6 py-4 flex flex-col gap-2">
                <div>
                    {deleteQuestion}
                </div>
                <div className="text-sm text-neutral-700">
                    {deleteText}
                </div>
            </div>
            <div>
                <div className="flex justify-between gap-4 px-6 py-4">
                    <button className="px-4 py-2 bg-main text-white rounded-md hover:bg-main" onClick={onClose}>Cancel</button>
                    <button className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700" onClick={delItem}>Delete Item</button>
                </div>
            </div>
        </Modal>
    )
}

export default AdminDelete;