import HTMLRenderer from '../../../../utils/HTMLRenderer';
import { IonIcon } from '@ionic/react';
import React from 'react';
import { Link } from 'react-router-dom';


interface HomeServicesCardProps {
    title: string;
    description: string;
    iconName: string;
    link: string;
}



const HomeServicesCard: React.FC<HomeServicesCardProps> = ({ title, description, link, iconName }) => {


    return (
        <Link to={`/services?service=${link}`} className="bg-neutral-100 rounded-md w-full flex items-center justify-start flex-col gap-6 py-8 px-2 cursor-pointer hover:scale-105 duration-150 select-none">
            <div className="h-20 w-20 bg-neutral-200 flex item-center justify-center rounded-full">
                <IonIcon icon={iconName} className="text-5xl text-main m-auto" />
            </div>
            <div className="w-full text-center text-lg text-center">
                <HTMLRenderer htmlContent={title} className="text-lg  " />
            </div>
            <div className="w-full px-3 text-center">
                <HTMLRenderer htmlContent={description} className="text-lg  " />
            </div>
            <div>
                <IonIcon icon='arrow-forward-outline' className='text-2xl text-main' />
            </div>
        </Link>
    )
}


export default HomeServicesCard;