import React, { useState, useEffect } from 'react';
import { defaultLanguage } from '../../../interfaces';
import backgroundVideo from '../../../assets/videos/smartattica_video.mp4';
import './HomeHeader.css';

const HomeHeader: React.FC<defaultLanguage> = ({ activeLanguage }) => {
    return (
        <section className='relative overflow-hidden w-full  flex justify-center items-center  mt-[96px]'>
            <div className='home-header-video-container'></div>
                <video autoPlay loop muted playsInline className='w-full'>
                    <source src={backgroundVideo} type="video/mp4" />
                </video>
        </section>
    )
}

export default HomeHeader;