import React, { useEffect, useState } from 'react';
import { defaultLanguage } from '../../interfaces';
import PartnerComponent from '../../Components/Misc/Partners/PartnerComponent';

const AssociatePartnersPage:React.FC<defaultLanguage> = ({activeLanguage}) => {

    return (
        <div className='mt-[120px]'>
            <PartnerComponent partnerType='associate' activeLanguage={activeLanguage} />
        </div>
    )
}

export default AssociatePartnersPage;