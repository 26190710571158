import React, { useState, useEffect } from "react";
import AdminMainHeader from "../../AdminMainHeader/AdminMainHeader";
import AdminBox from "../../Helpers/AdminBox/AdminBox";
import { Button } from "../../../../@/components/ui/button";
import { IonIcon } from "@ionic/react";
import AdimBoxTitle from "../../Helpers/AdminBoxTitle/AdimBoxTitle";
import { useAuthContext } from '../../../../utils/useAuthContext';
import { ACTIVE_URL } from '../../../../constants';
import { Input } from "../../../../@/components/ui/input";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "../../../..//@/components/ui/select";

interface AddPartnersProps {
    onComplete: () => void;
}

const AddPartners: React.FC<AddPartnersProps> = ({ onComplete }) => {
    const { authTokens } = useAuthContext();
    const [partName, setPartName] = useState<string>('');
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [partLink, setPartLink] = useState<string>('');
    const [partType, setPartType] = useState<string>('consortium');
    const [orgType, setOrgType] = useState<string>('');

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            const selected = files[0];
            if (selected.type.startsWith('image/')) {
                setSelectedFile(selected);
            } else {
                console.log('Invalid file type. Please select an image.');
            }
        }
    };

    const emptyFields = () => {
        setPartName('');
        setSelectedFile(null);
        setPartLink('');
        setPartType('consortium');
    }

    const submitForm = async () => {
        const formData = new FormData();
        formData.append('name', partName);
        formData.append('image', selectedFile as Blob);
        formData.append('partner_link', partLink);
        formData.append('partner_type', partType);
        formData.append('org_type', orgType);

        try {
            const response = await fetch(`${ACTIVE_URL}/post_partner/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${authTokens?.access}`,
                },
                body: formData
            });
            if (response.ok) {
                emptyFields();
                onComplete();
            } else {
                console.error('Failed to add partner');
            }
        } catch (error) {
            console.error('Failed to add partner');
        }
    }

    const onDismiss = () => {
        emptyFields();
        onComplete();
    }

    return (
        <div>
            <AdminMainHeader pageTitle='Partners' />
            <AdminBox>
                <div className="flex w-full justify-between items-center ">
                    <AdimBoxTitle title='Add Partner' />
                    <div className="mr-2">
                        <Button variant="outline" className="px-3 h-9 text-white bg-neutral-700 hover:bg-neutral-800 hover:text-white" onClick={onDismiss}>
                            <IonIcon icon="remove" className="mr-1 text-lg" />
                            Dismiss
                        </Button>
                    </div>
                </div>

                <div className='px-4 flex flex-col gap-4 mb-2'>
                    <div className='flex flex-col gap-2'>
                        <label className='text-lg text-neutral-800' htmlFor="parent_name">Partner Name</label>
                        <input className=' outline-none border-b border-b-neutral-300' type="text" id="parent_name" placeholder="e.g. NCSR Demokritos" value={partName} onChange={(e) => setPartName(e.target.value)} />
                    </div>
                    <div className='flex flex-col gap-2'>
                        <label className='text-lg text-neutral-800' htmlFor="org_type">Org Type</label>
                        <input className=' outline-none border-b border-b-neutral-300' type="text" id="org_type" placeholder="e.g. SME" value={orgType} onChange={(e) => setOrgType(e.target.value)} />
                    </div>
                    <div className='flex flex-col gap-2'>
                        <label className='text-lg text-neutral-800' htmlFor="picture">Display Image</label>
                        <Input id="picture" type="file" onChange={handleFileChange} accept="image/jpeg, image/jpg, image/png, image/gif" />
                    </div>
                    <div className='flex flex-col gap-2'>
                        <label className='text-lg text-neutral-800' htmlFor="partner_link">Partner Link</label>
                        <input className=' outline-none border-b border-b-neutral-300' type="text" id="partner_link" placeholder="e.g. www.demokritos.gr" value={partLink} onChange={(e) => setPartLink(e.target.value)} />
                    </div>
                    <div className='flex flex-col gap-2'>
                        <label className='text-lg text-neutral-800' htmlFor="partner_link">Partner Type</label>
                        <Select onValueChange={(option: string) => setPartType(option)} value={partType}>
                            <SelectTrigger className="w-[180px] outline-none h-8">
                                <SelectValue placeholder="Select User Group" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectGroup>
                                    <SelectItem key={`consortium`} value={'consortium'} >
                                        Consortium Partner
                                    </SelectItem>
                                    <SelectItem key={`associate`} value={'associate'} >
                                        Associate Partner
                                    </SelectItem>
                                </SelectGroup>
                            </SelectContent>
                        </Select>
                    </div>
                    <div>
                        <Button variant="outline" className="px-3 h-9 text-white bg-main hover:bg-main hover:text-white" onClick={submitForm}>
                            <IonIcon icon="add" className="mr-1 text-lg" />
                            Add Partner
                        </Button>
                    </div>
                </div>

            </AdminBox>


        </div>
    )

}


export default AddPartners;