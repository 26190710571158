import Skeleton, { SkeletonTheme, SkeletonStyleProps } from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
interface SkeletonLoaderProps {
    height?: string;
    width?: string;
    isCircle?: boolean;
    className?: string;
    borderRadius?: number;
}
const SkeletonLoader: React.FC<SkeletonLoaderProps> = ({ height, width, isCircle = false, className='', borderRadius }) => {

    return (
        <SkeletonTheme baseColor="rgb(209 209 209)"  >
            <section className="rounded-md">
                {isCircle ? <Skeleton circle height={height ? height : '100%'} width={width ? width : '50px'} borderRadius={borderRadius} containerClassName={className} /> : <Skeleton borderRadius={borderRadius} height={height ? height : '100%'} width={width ? width : '50px'} />}

            </section>
        </SkeletonTheme>
    )
}
export default SkeletonLoader;