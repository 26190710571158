import React, { useEffect, useState } from 'react';
import { NewsItem } from "../AdminNews";
import { ACTIVE_URL, ACTIVE_MEDIA_URL } from '../../../../constants';
import { useAuthContext } from '../../../../utils/useAuthContext';
import { IonIcon, IonSpinner } from '@ionic/react';
import { Button } from '../../../../@/components/ui/button';
import { Input } from '../../../../@/components/ui/input';
import { DatePicker } from '../../Helpers/SimpleDatePicker/SimpleDatePicker';
import CKEditorComponent from '../../../../utils/QuillEditor/QuillEditor';
import Switch from '../../../../utils/Switch/Switch';
import NewsTagEditSection from '../NewsTagEditSection/NewsTagEditSection';
import { TagType } from '@/src/interfaces';
import AdminDelete from '../../AdminDelete/AdminDelete';
import ImagesCarousel from '../../../../Pages/NewsPage/ImagesCarousel/ImagesCarousel';

interface AdminNewsItemProps {
    item: NewsItem;
    allTags: TagType[];
    loading: boolean;
    onUpdate: () => void;
}

const AdminNewsItem: React.FC<AdminNewsItemProps> = ({ item, loading, allTags, onUpdate }) => {
    const [mode, setMode] = useState<string>('overview');
    const [itemData, setItemData] = useState<NewsItem>(item);
    const [newAdditionalImages, setNewAdditionalImages] = useState<File[]>([]);
    const [newPDFs, setNewPDFs] = useState<File[]>([]);
    const { authTokens } = useAuthContext();
    const [tagToBeAdded, setTagToBeAdded] = useState<TagType>({} as TagType);
    const [showDelete, setShowDelete] = React.useState<boolean>(false);
    const [isDeleted, setIsDeleted] = React.useState<boolean>(false);
    const [removedPDFs, setRemovedPDFs] = useState<string[]>([]);
    const [removedImages, setRemovedImages] = useState<string[]>([]);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);

    const closeDelete = () => {
        setShowDelete(false);
    }


    const editNewNews = async () => {
        if (!authTokens || !authTokens.access) {
            return;
        }
        try {
            setIsUpdating(true);
            const formData = new FormData();
            const tagIds = itemData.tags.map(tag => tag.id).join(',');
            formData.append('tags', tagIds);

            formData.append('id', itemData.id.toString());
            formData.append('gr_title', itemData.grTitle);
            formData.append('en_title', itemData.enTitle);
            formData.append('gr_description', itemData.grDescription);
            formData.append('en_description', itemData.enDescription);
            formData.append('date', itemData.date);
            formData.append('slug', itemData.slug);
            formData.append('featured', itemData.featured ? 'true' : 'false');
            if (itemData.image instanceof File && typeof (itemData.image) !== 'string') {
                formData.append('image', itemData.image);
            } else if (typeof (itemData.image) === 'string') {
                formData.append('image', 'none');
            } else {
                formData.append('image', 'none');
            }

            // Append IDs of removed PDF and image attachments
            formData.append('pdfs_to_remove', removedPDFs.join(','));
            formData.append('images_to_remove', removedImages.join(','));

            // Append new PDF and image attachments
            newPDFs.forEach((pdf, index) => {
                formData.append(`pdf_${index}`, pdf);
            });

            newAdditionalImages.forEach((image, index) => {
                formData.append(`image_${index}`, image);
            });

            const response = await fetch(`${ACTIVE_URL}/edit_news/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${authTokens.access}`,
                },
                body: formData,
            });

            if (response.ok) {
                const data = await response.json();
                setNewAdditionalImages([]);
                setNewPDFs([]);
                toggleMode();
                onUpdate();
            } else {
                console.error('Failed to fetch responses');
            }
        } catch (error) {
            console.error('Error fetching responses:', error);
        } finally {
            setIsUpdating(false);
        }
    };



    useEffect(() => {
        setItemData(item);
    }, [item]);

    const toggleMode = () => {
        setMode(mode => mode === 'overview' ? 'edit' : 'overview');
    }

    const setDate = (date: string) => {
        setItemData({ ...itemData, date: date });
    }

    const setImageFile = (file: File) => {
        setItemData({ ...itemData, image: file });
    }

    const resetItem = () => {
        setItemData(item);
        setNewAdditionalImages([]);
        setNewPDFs([]);
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            const selected = files[0];
            if (selected.type.startsWith('image/')) {
                setImageFile(selected);
            } else {
                console.log('Invalid file type. Please select an image.');
            }
        }
    };

    const handlePDFChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
            const pdfs = Array.from(files).filter(file => file.type === 'application/pdf');
            setNewPDFs([...newPDFs, ...pdfs]);
        }
    };

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
            const images = Array.from(files).filter(file => file.type.startsWith('image/'));
            setNewAdditionalImages([...newAdditionalImages, ...images]);
        }
    };

    let imageUrl: string | undefined;

    if (itemData.image instanceof File) {
        imageUrl = URL.createObjectURL(itemData.image);
    } else {
        imageUrl = itemData.image;
    }
    const backgroundImageStyle = {
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url('data:image/jpeg;base64,${imageUrl}')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '298px',
        height: '10rem',
    };

    const handleTagSelectionChange = (selectedId: string | number) => {
        const selectedTag = allTags.find(tag => tag.id === selectedId);
        if (selectedTag) {
            setTagToBeAdded(selectedTag);
        }
    };

    const handleAddToSelectedTags = () => {
        if (tagToBeAdded.id) {
            setItemData({ ...itemData, tags: [...itemData.tags, tagToBeAdded] });
        }
    }

    const unselectTag = (tagId: string) => {
        const newTags = itemData.tags.filter((tag) => tag.id !== tagId);
        setItemData({ ...itemData, tags: newTags });
    }

    const deleteAttachment = (type: string, index: number, id: string) => {
        if (type === 'pdf') {
            setRemovedPDFs([...removedPDFs, id]);
            setItemData({
                ...itemData,
                pdfAttachments: itemData.pdfAttachments.filter((_, i) => i !== index),
            });
        } else if (type === 'image') {
            setRemovedImages([...removedImages, id]);
            setItemData({
                ...itemData,
                additionalImages: itemData.additionalImages.filter((_, i) => i !== index),
            });
        }
    }

    if (isDeleted) {
        return null;
    }


    return (
        <>
            {
                mode === 'overview' ?
                    <>
                        <div className="flex items-center select-none justify-between border-main border p-1.5 rounded-md hover:shadow-md hover:bg-neutral-50">
                            <div className='flex items-center gap-3 w-full'>
                                <div className=''>
                                    {itemData.date ? new Date(itemData.date).toLocaleDateString('en-GB') : ''}
                                </div>
                                <div className='h-full flex items-center gap-1 w-20'>
                                    <div className={`h-3 w-3 rounded-full ${itemData.featured ? 'bg-main' : (itemData.active ? 'bg-green-600' : 'bg-red-600')}`}>
                                    </div>
                                    <div className=''>
                                        {itemData.featured ? 'Featured' : (itemData.active ? 'Active' : 'Inactive')}
                                    </div>
                                </div>
                                <div className=''>
                                    {item.grTitle}
                                </div>
                            </div>
                            <div className='flex items-center'>
                                <IonIcon icon='trash' className='text-lg text-red-600 cursor-pointer rounded-md hover:bg-neutral-200 p-2' onClick={() => setShowDelete(true)} />
                                <IonIcon icon='pencil' className='text-lg text-main cursor-pointer rounded-md hover:bg-neutral-200 p-2' onClick={toggleMode} />
                            </div>
                        </div>
                        <AdminDelete
                            show={showDelete}
                            onClose={closeDelete}
                            titleText={'Delete News Item'}
                            deleteQuestion={'Are you sure you would like to delete this news item?'}
                            deleteText={itemData.grTitle}
                            deleteAPIUrl={`${ACTIVE_URL}/delete_news/${itemData.id}`}
                            onDelete={() => setIsDeleted(true)}
                        />
                    </>
                    :
                    <>
                        <div className="hover:shadow-md w-full select-none rounded-md border-main border py-2.5 shadow-md px-4 bg-neutral-50">
                            <div className='flex items-center justify-between'>
                                <div className='w-[90%] flex items-center h-full'>
                                    <div className='w-[30%]'>
                                        <div className='w-4/5'>
                                            <DatePicker onDateChange={(setDate)} selectedDate={itemData.date} />
                                        </div>
                                    </div>
                                    <div className='w-[45%]'>
                                        <Input
                                            className='h-9 focus-visible:ring-none outline-none ring-offset-0 w-4/5'
                                            placeholder='Greek Title'
                                            value={itemData.grTitle}
                                            onChange={(event) => setItemData({ ...itemData, grTitle: event.target.value })}
                                        />
                                    </div>
                                    <div className='h-full flex items-center text-center gap-1.5 w-[40.1%]'>
                                        <Switch checked={itemData.featured} wrapperClass='mt-1.5' onChange={(ch) => setItemData({ ...itemData, featured: ch })} inactiveColorClass='bg-green-600' />
                                        <div>
                                            {itemData.featured ? 'Featured' : 'Active'}
                                        </div>
                                    </div>
                                </div>
                                <div className='w-[180px] flex items-center justify-between'>
                                    <Button variant="outline" className="px-3 h-9 text-white bg-neutral-600 hover:bg-neutral-800 hover:text-white" onClick={() => { resetItem(); toggleMode(); }}>
                                        <IonIcon icon="close" className="mr-2 text-lg" />
                                        Discard Changes
                                    </Button>
                                </div>
                            </div>
                            <div className='px-2 py-4 flex flex-col gap-5'>
                                <div className='flex flex-col gap-2'>
                                    <label className='text-lg text-neutral-800 ' htmlFor="slug">Slug Address</label>
                                    <input className=' outline-none border-b border-b-neutral-300 w-[300px] py-1 rounded-md px-2.5' type="text" id="title_gr" value={itemData.slug} onChange={(e) => setItemData({ ...itemData, slug: e.target.value })} />
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <label className='text-lg text-neutral-800' htmlFor="title_en">English Title</label>
                                    <input className=' outline-none border-b border-b-neutral-300 w-[300px] py-1 rounded-md px-2.5' type="text" id="title_en" value={itemData.enTitle} onChange={(e) => setItemData({ ...itemData, enTitle: e.target.value })} />
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <label className='text-lg text-neutral-800' htmlFor="title_gr">Greek Description</label>
                                    <CKEditorComponent className=' outline-none border-b border-b-neutral-300' initialValue={itemData.grDescription} onChange={(e) => setItemData({ ...itemData, grDescription: e })} />
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <label className='text-lg text-neutral-800' htmlFor="title_gr">English Description</label>
                                    <CKEditorComponent className=' outline-none border-b border-b-neutral-300' initialValue={itemData.enDescription} onChange={(e) => setItemData({ ...itemData, enDescription: e })} />
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <label className='text-lg text-neutral-800' htmlFor="picture">Display Image</label>
                                    {
                                        itemData.image instanceof File ? (
                                            <div className='w-[300px]'>
                                                <img src={URL.createObjectURL(itemData.image)} className='rounded-md w-auto' alt="Selected image" />
                                                <p className='mt-2 text-sm text-gray-500'>New image selected</p>
                                            </div>
                                        ) : (
                                            <div className='w-[300px]'>
                                                <img src={`${ACTIVE_MEDIA_URL}${itemData.image}`} className='rounded-md w-auto' alt="Current image" />
                                                <p className='mt-2 text-sm break-all text-gray-500'>
                                                    Image URL: <a href={`${ACTIVE_MEDIA_URL}${itemData.image}`} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                                                        {`${ACTIVE_MEDIA_URL}${itemData.image}`}
                                                    </a>
                                                </p>
                                            </div>
                                        )
                                    }
                                    <Input id="picture" type="file" onChange={handleFileChange} accept="image/jpeg, image/jpg, image/png, image/gif" className='w-[300px] cursor-pointer' />
                                </div>
                                <NewsTagEditSection tags={allTags} selectedTags={itemData.tags} handleAddToSelectedTags={handleAddToSelectedTags} handleTagSelectionChange={handleTagSelectionChange} unselectTag={unselectTag} />
                                <div className='flex flex-col gap-2'>
                                    <label className='text-lg text-neutral-800' htmlFor="image_attachments">Additional Images</label>
                                    <div className='flex flex-wrap gap-2'>
                                        {itemData.additionalImages.map((image, index) => (
                                            <div key={index} className='relative border rounded-md'>
                                                <img src={`${ACTIVE_MEDIA_URL}${image.url}`} className='w-[150px] h-[100px] object-cover rounded-md ' />
                                                <IonIcon icon='trash' className='absolute top-1 right-1 text-lg text-red-600 cursor-pointer' onClick={() => deleteAttachment('image', index, image.id)} />
                                            </div>
                                        ))}
                                        {newAdditionalImages.map((image, index) => (
                                            <div key={index} className='relative border rounded-md'>
                                                <img src={URL.createObjectURL(image)} className='w-[100px] h-[100px] object-cover rounded-md' />
                                                <IonIcon icon='trash' className='absolute top-1 right-1 text-lg text-red-600 cursor-pointer' onClick={() => setNewAdditionalImages(newAdditionalImages.filter((_, i) => i !== index))} />
                                            </div>
                                        ))}
                                    </div>
                                    <Input id="image_attachments" type="file" onChange={handleImageChange} accept="image/jpeg, image/jpg, image/png, image/gif" multiple className='w-[300px] cursor-pointer' />
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <label className='text-lg text-neutral-800 flex flex-col gap-2' htmlFor="pdf_attachments">PDF Attachments</label>
                                    {itemData.pdfAttachments.map((pdf, index) => (
                                        <li key={index} className='flex justify-between items-center rounded-md border border-main w-fit bg-white px-2'>
                                            <div className='flex gap-2 items-center justify-center'>
                                                <IonIcon icon='document' className='text-main' />
                                                <a href={`${ACTIVE_MEDIA_URL}${pdf.url}`} target="_blank" rel="noopener noreferrer">{pdf.name}</a>
                                                <IonIcon icon='trash' className='text-lg text-red-600 cursor-pointer' onClick={() => deleteAttachment('pdf', index, pdf.id)} />
                                            </div>

                                        </li>
                                    ))}
                                    {newPDFs.map((pdf, index) => (
                                        <li key={index} className='flex justify-between items-center rounded-md border border-main w-fit bg-white px-2'>
                                            <div className='flex gap-2 items-center justify-center'>
                                                <IonIcon icon='document' className='text-main' />
                                                <a href={URL.createObjectURL(pdf)} target="_blank" rel="noopener noreferrer">{pdf.name}</a>
                                                <IonIcon icon='trash' className='text-lg text-red-600 cursor-pointer' onClick={() => setNewPDFs(newPDFs.filter((_, i) => i !== index))} />
                                            </div>
                                        </li>
                                    ))}
                                    <Input id="pdf_attachments" type="file" onChange={handlePDFChange} accept="application/pdf" multiple className='w-[300px] cursor-pointer' />
                                </div>
                                <div>
                                    <Button variant="outline" className="px-4 h-10 text-white bg-main hover:bg-blue-600 hover:text-white" onClick={editNewNews} disabled={isUpdating}>
                                        {isUpdating ? (
                                            <IonSpinner name="crescent" className="mr-2" />
                                        ) : (
                                            <>
                                                <IonIcon icon="cloud-done" className="mr-2 text-lg" />
                                                Confirm Changes
                                            </>
                                        )}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </>
            }
        </>
    )
}

export default AdminNewsItem;
