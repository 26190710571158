import { ACTIVE_MEDIA_URL } from '../../../..//constants';
import React, { useState, useEffect } from 'react';

interface PartnerImageProps {
  image: string | File;
}

const PartnerImage: React.FC<PartnerImageProps> = ({ image }) => {
  const [imageSrc, setImageSrc] = useState<string>(() => (image instanceof File ? '' : image));

  useEffect(() => {
    if (image instanceof File) {
      const objectURL = URL.createObjectURL(image);
      setImageSrc(objectURL);
      return () => URL.revokeObjectURL(objectURL);
    }
    else {
      setImageSrc(image);
    }
  }, [image]);

  return (
    <img src={ACTIVE_MEDIA_URL + imageSrc} alt="" className="max-h-[100%] w-auto" />
  );
}

export default PartnerImage;
