import React, { useEffect, useState } from "react";
import { defaultLanguage } from '../../interfaces';
import espa from '../../assets/images/logos/sa_espa_badge.jpg';
import edihLogo from '../../assets/images/extra_logos/L4_DMAs.jpg'
import edihN from '../../assets/images/extra_logos/edihn.png'
import { IonIcon } from "@ionic/react";
import { useLocation } from 'react-router-dom';
import espaGr from '../../assets/images/logos/antagonistikotitaframeEL.jpg';
import greekLanguage from '../../locales/gr.json';
import englishLanguage from '../../locales/en.json';
import fetchSiteText from "../../utils/GetSiteText/GetSiteText";
import HTMLRenderer from "../../utils/HTMLRenderer";

const Footer: React.FC<defaultLanguage> = ({ activeLanguage }) => {
    const location = useLocation();

    const [titleGr, setTitleGr] = useState<string>('');
    const [titleEn, setTitleEn] = useState<string>('');
    const [euTextGr, setEuTextGr] = useState<string>('');
    const [euTextEn, setEuTextEn] = useState<string>('');
    const [locationGr, setLocationGr] = useState<string>('');
    const [locationEn, setLocationEn] = useState<string>('');

    useEffect(() => {
        const fetchResponses = async () => {
            const footerTitleData = await fetchSiteText('footer_title');
            const euTextData = await fetchSiteText('footer_eu_text');
            const footerLocationData = await fetchSiteText('footer_location');
            if (footerTitleData && euTextData && footerLocationData) {
                setTitleGr(footerTitleData.gr_text);
                setTitleEn(footerTitleData.en_text);
                setEuTextGr(euTextData.gr_text);
                setEuTextEn(euTextData.en_text);
                setLocationGr(footerLocationData.gr_text);
                setLocationEn(footerLocationData.en_text);
            }
        };
        fetchResponses();
    }, []);

    if (location.pathname.startsWith('/admin')) {
        return null;
    }

    return (
        <div className="bg-main pt-8 md:pt-16 pb-6 w-full">
            <div className="content-max-w m-auto text-white px-4 md:px-0">
                <h3 className="text-2xl md:text-4xl mb-6 md:mb-10 raisonne-pro-font">
                    <HTMLRenderer htmlContent={activeLanguage == 'en' ? titleEn : titleGr} className={`${activeLanguage == 'el' ? 'open-sans-font ' : 'raisonne-pro-font'}`} />
                </h3>
                <div className="flex flex-col md:flex-row gap-8 md:gap-16 mb-4">
                    <div className="w-full md:w-[49%] text-white flex flex-col gap-4">
                        <div className="flex gap-5 py-1 items-center">
                            <IonIcon icon='location' color='white text-xl md:text-5xl' />
                            <div className="w-3/4 md:w-auto">
                                <HTMLRenderer htmlContent={activeLanguage == 'en' ? locationEn : locationGr} className="text-sm" />
                            </div>
                        </div>
                        <div className="flex gap-5 py-1 items-center">
                            <IonIcon icon='call' color='white text-xl md:text-2xl' />
                            <div>
                                +30 210 6503465
                            </div>
                        </div>
                        <div className="flex gap-5 py-1 items-center">
                            <IonIcon icon='mail' color='white text-xl md:text-2xl' />
                            <a href="mailto:in<!-- wergwergq3 -->fo<!-- fewrgdf -->@smar<!—efc3g3fw43f -->tattica.e<!-- 23r4erge243 -->u">info@smartattica.eu</a>
                        </div>
                        <div className="flex gap-5 py-1 items-center">
                            <div className="flex gap-2">
                                <a href="https://www.linkedin.com/company/smart-attica-european-digital-innovation-hub/">
                                    <IonIcon icon='logo-linkedin' color='white text-2xl md:text-3xl' />
                                </a>
                                <a href="https://www.youtube.com/@smartatticaedih">
                                    <IonIcon icon='logo-youtube' color='white text-2xl md:text-3xl' />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-[51%]">
                        <img src={activeLanguage == 'en' ? espa : espaGr } alt="" className="mb-4 max-w-full" />
                        <HTMLRenderer htmlContent={activeLanguage == 'en' ? euTextEn : euTextGr} className="text-sm" />
                    </div>
                </div>
                <div className="w-full flex flex-col md:flex-row items-center justify-center gap-4 mt-4 md:mt-0 md:gap-8 mb-6">
                    <a href="https://european-digital-innovation-hubs.ec.europa.eu/edih-catalogue/smart-attica-edih-website" target="_blank" rel="noopener noreferrer">
                        <img src={edihN} alt="" className="h-[30px] md:h-[40px] rounded-sm" />
                    </a>
                    <a href="https://www.smartattica.eu/news/dmas-120-beneficiaries" target="_blank" rel="noopener noreferrer">
                        <img src={edihLogo} alt="" className="h-[50px] md:h-[70px] rounded-sm" />
                    </a>
                </div>
                <div className="text-center text-xs md:text-sm">
                    © 2024 Smart Attica EDIH. All Rights Reserved
                </div>
            </div>
        </div>
    )
}

export default Footer;